import LinkHeader from 'http-link-header';
import { Cursor, PaginationCursors } from '../../types/pagination.type';

const fromLinkHeader = (value: string): PaginationCursors => {
  const link = LinkHeader.parse(value || '');
  const [before] = link.rel('before');
  const [after] = link.rel('after');

  return {
    before: before ? before.uri : undefined,
    after: after ? after.uri : undefined,
  };
};

const update = (
  cursorUsed: Cursor,
  oldCursors: PaginationCursors,
  newCursors: PaginationCursors,
): PaginationCursors => {
  if (cursorUsed === 'after') {
    return {
      before: oldCursors.before ? oldCursors.before : newCursors.before,
      after: newCursors.after ? newCursors.after : oldCursors.after,
    };
  }
  return {
    before: newCursors.before ? newCursors.before : oldCursors.before,
    after: oldCursors.after ? oldCursors.after : newCursors.after,
  };
}

export default {
  fromLinkHeader,
  update,
};
