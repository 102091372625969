import axios from '../../../privateApi';

type Match = {
  team_home_id: string,
  team_away_id: string,
  team_home_score: number,
  team_away_score: number,
  duration: number;
  recorded_date: string,
  owner_id: string,
  url: string;
};

export type EditMatch = {
  league_id?: string,
  team_home_id?: string,
  team_away_id?: string,
  team_home_score?: number,
  team_away_score?: number,
  recorded_date?: string,
};

export type DateRange = {
  start: string;
  end: string;
};

export const getMatch = (matchId: string, leagueId: string) => axios.get(`leagues/${leagueId}/matches?id=${matchId}`)
  .then((res) => res.data);

export const postMatch = (match: Match, leagueId: string) => axios.post(`leagues/${leagueId}/matches`, match);

export const getMatchesFromLeague = (leagueId: string, teamName?: string, dateRange?: DateRange) => {
  const searchParams = new URLSearchParams();

  if (process.env.REACT_APP_USE_CURSOR_PAGINATION !== 'true') {
    searchParams.append('page', '1');
    searchParams.append('per_page', '100');

    if (teamName) {
      searchParams.append('team_name', teamName);
    }

    if (dateRange) {
      searchParams.append('start', dateRange.start);
      searchParams.append('end', dateRange.end);
    }
  }

  return axios.get(`leagues/${leagueId}/matches`, { params: searchParams });
};

export const getPaginatedMatchesFromLeague = (url: string) => axios.get(url);

export const deleteMatch = (leagueId: string, matchId: string) => axios.delete(`leagues/${leagueId}/matches/${matchId}/`);

export const editMatch = (
  leagueId: string,
  matchId: string,
  updatedValues: EditMatch,
) => axios.put(`leagues/${leagueId}/matches/${matchId}/`, updatedValues);
