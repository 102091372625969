import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DropdownLeague from '../../components/Dropdown/DropdownLeague';
import ErrorPage from '../../components/ErrorPage/ErrorPage';
import Spinner from '../../components/Spinner/Spinner';
import { APIStatus } from '../../enums/APIStatus';
import {
  fetchLeagues,
  getCurrentLeagueSelector,
  getLeaguesSelector,
  getStatusSelector,
  setCurrentLeague,
} from '../../redux/features/leagueSlice';
import { getUserSelector } from '../../redux/features/userSlice';
import { AppDispatch } from '../../redux/store';

type Props = {
  children: React.ReactNode;
};

function LeagueContainer({ children }: Props) {
  const dispatch = useDispatch<AppDispatch>();

  const status = useSelector(getStatusSelector);
  const currentLeague = useSelector(getCurrentLeagueSelector);
  const leagues = useSelector(getLeaguesSelector);
  const { user } = useSelector(getUserSelector);

  const onChange = (newLeague: any) => {
    dispatch(setCurrentLeague(newLeague));
  };

  useEffect(() => {
    if (user?.veo_id) {
      dispatch(fetchLeagues(user.veo_id));
    }
  }, [user]);

  if (leagues && currentLeague) {
    return (
      <div>
        <DropdownLeague
          value={currentLeague!}
          leagues={leagues}
          onChange={onChange}
        />
        {children}
      </div>
    );
  }

  if (status === APIStatus.IDLE || status === APIStatus.PENDING) {
    return <Spinner />;
  }

  return (
    <ErrorPage
      title="You don&apos;t have access to this league exchange "
      text="Please contact a league exchange admin to add you as a member or verify that you have signed up using an email address which has received a league exchange invitation."
      warningText={(
        <>
          You are currently logged in as&nbsp;
          <strong>
            {user?.email}
          </strong>
        </>
)}
    />
  );
}

export default LeagueContainer;
