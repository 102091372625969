import React from 'react';
import styles from './IconButton.module.scss';

type Props = {
  onClick?: () => void;
  disabled?: boolean;
  children: JSX.Element;
};

const IconButton = ({
  onClick,
  disabled,
  children,
}: Props) => (
  <button
    type="button"
    onClick={onClick}
    disabled={disabled}
    className={styles['icon-button']}
  >
    {children}
  </button>
);

export default IconButton;
