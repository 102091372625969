import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { APIStatus } from '../../enums/APIStatus';
import { IRootState } from '../store';
import {
  getLeagueMembers,
  inviteLeagueMember,
  deleteTeamMember,
  updateTeamMember,
} from '../../apis/privateApi';
import {
  Member,
  MemberAddType,
  TeamMemberDeleteType,
  TeamMemberUpdate,
} from '../../types/member.type';
import { Message } from '../../types/message.type';
import { MessageType } from '../../enums/MessageType';

const initialState = {
  status: APIStatus.IDLE,
  members: [] as Member[],
  message: {} as Message,
};

export const fetchLeagueMembers = createAsyncThunk(
  'leagueMembers',
  async (leagueId: string) => (
    getLeagueMembers(leagueId).then((response) => response.data)
  ),
);

export const editTeamMember = createAsyncThunk(
  'editTeamMember',
  async ({
    leagueId, userId, teamId, permission,
  }: TeamMemberUpdate) => {
    await updateTeamMember(leagueId, teamId, userId, permission);
    return getLeagueMembers(leagueId).then((response) => response.data);
  },
);

export const deleteMemberFromTeam = createAsyncThunk(
  'deleteMemberFromTeam',
  async ({ leagueId, teamId, userId }: TeamMemberDeleteType) => {
    await deleteTeamMember(leagueId, teamId, userId);
    return getLeagueMembers(leagueId).then((response) => response.data);
  },
);

export const inviteMemberToLeague = createAsyncThunk(
  'addMember',
  async ({ data, leagueId }: MemberAddType) => (
    inviteLeagueMember(data, leagueId)
      .then((response) => response.data).catch((error) => {
        throw new Error(error.response.data.message);
      })),
);

const membersSlice = createSlice({
  name: 'members',
  initialState,
  reducers: {
    clearMemberMessage: (state) => {
      state.message = {} as Message;
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch member
      .addCase(fetchLeagueMembers.pending, (state) => {
        state.status = APIStatus.PENDING;
      })
      .addCase(fetchLeagueMembers.fulfilled, (state, action) => {
        state.status = APIStatus.FULFILLED;
        state.members = action.payload.data;
      })
      .addCase(fetchLeagueMembers.rejected, (state) => {
        state.status = APIStatus.REJECTED;
      })

      // Edit member
      .addCase(editTeamMember.pending, (state) => {
        state.status = APIStatus.PENDING;
      })
      .addCase(editTeamMember.fulfilled, (state, action) => {
        state.status = APIStatus.FULFILLED;
        state.members = action.payload.data;
        state.message = {
          title: 'Member updated',
          message: 'The role for the member has been updated',
          type: MessageType.UPDATED,
        };
      })
      .addCase(editTeamMember.rejected, (state) => {
        state.status = APIStatus.REJECTED;
        state.message = {
          title: 'Error',
          message: 'An error occured while editing member. Try again later',
          type: MessageType.ERROR,
        };
      })

      // Delete member
      .addCase(deleteMemberFromTeam.pending, (state) => {
        state.status = APIStatus.PENDING;
      })
      .addCase(deleteMemberFromTeam.fulfilled, (state, action) => {
        state.status = APIStatus.FULFILLED;
        state.members = action.payload.data;
        state.message = {
          title: 'Team member removed',
          message: 'The member has been removed from the team',
          type: MessageType.DELETED,
        };
      })
      .addCase(deleteMemberFromTeam.rejected, (state) => {
        state.status = APIStatus.REJECTED;
        state.message = {
          title: 'Error',
          message: 'An error occured while deleting a team member. Try again later',
          type: MessageType.ERROR,
        };
      })

      // Invite member
      .addCase(inviteMemberToLeague.rejected, (state, action) => {
        state.status = APIStatus.REJECTED;
        if (action.error?.message?.includes('has already been invited to this league.')) {
          state.message = {
            title: 'Error',
            message: action.error.message,
            type: MessageType.ERROR,
          };
        } else if (action.error?.message?.includes('User is already in team.')) {
          state.message = {
            title: 'Error',
            message: 'User is already in team.',
            type: MessageType.ERROR,
          };
        } else {
          state.message = {
            title: 'Error',
            message: 'An error occured while inviting member. Try again later',
            type: MessageType.ERROR,
          };
        }
      })
      .addCase(inviteMemberToLeague.fulfilled, (state) => {
        state.status = APIStatus.FULFILLED;
        state.message = {
          title: 'Invite sent',
          message: 'Invitation to join the league has now been sent to the users\' emails',
          type: MessageType.UPDATED,
        };
      });
  },
});

export const { clearMemberMessage } = membersSlice.actions;

export const getMembersMessage = (state: IRootState) => state.membersReducer.message;

export const getLeagueMembersStateSelector = (state: IRootState) => (
  state.membersReducer
);

export const getLeagueMembersSelector = (state: IRootState) => (
  state.membersReducer.members
);

export default membersSlice.reducer;
