import React from 'react';

import { formatRole } from '../../../enums/Role';
import { Member } from '../../../types/member.type';
import ArrowDownTooltip from '../../Tooltip/ArrowDownTooltip/ArrowDownTooltip';
import Chip from '../../Chip/Chip';
import styles from './MembersList.module.scss';
import MembersListItemOptions from './MembersListItemOptions';

type MembersListItemProps = {
  member: Member;
  showOptions: boolean;
};

function MembersListItem({ member, showOptions }: MembersListItemProps) {
  return (
    <div>
      <main>
        <div className={styles['members-list']} key={Math.random()}>
          {member.pending && (
            <div className={styles['members-list-row']}>
              <div className={`${styles['members-list-row-name']} ${styles['members-list-disabled']}`}>
                -
              </div>
              <div className={`${styles['members-list-row-email']} ${styles['members-list-disabled']}`}>{member.user?.email}</div>
              <div className={`${styles['members-list-row-team']} ${styles['members-list-disabled']}`}>
                {member.team_name}
              </div>
              <div className={`${styles['members-list-row-role']} ${styles['members-list-disabled']}`}>
                {formatRole(member.permission)}
              </div>
              <div className={styles['members-list-row-pending']}>
                {member.pending
                    && (
                    <Chip tagText="Pending">
                      <div className={styles['members-list-row-pending-tooltip']}>
                        <ArrowDownTooltip tooltipText="This user has not accepted the invitation yet" />
                      </div>
                    </Chip>
                    )}
              </div>
            </div>
          )}
          <div>
            {!member.pending && (
              <div className={styles['members-list-row']}>
                <div className={styles['members-list-row-name']}>
                  {`${member.user?.first_name} ${member.user?.last_name}`}
                </div>
                <div className={styles['members-list-row-email']}>{member.user?.email}</div>
                <div className={styles['members-list-row-team']}>
                  {member.team_name}
                </div>
                <div className={styles['members-list-row-role']}>
                  {formatRole(member.permission)}
                </div>
                <div />
                <div className={styles['members-list-row-options']}>
                  {showOptions
                    && (
                    <MembersListItemOptions
                      member={member}
                    />
                    )}
                </div>
              </div>
            )}
          </div>
        </div>
      </main>
    </div>
  );
}

export default MembersListItem;
