import React from 'react';
import { optionsVertical } from '../../icons';
import OptionsMenu from './OptionsMenu/OptionsMenu';
import styles from './Options.module.scss';
import CloseComponentOutside from '../Wrapper/CloseComponentOutside';

type Props = {
  icon?: JSX.Element;
  options?: any;
};

function Options({
  options,
  icon,
}: Props) {
  const { ref, isComponentVisible, setIsComponentVisible } = CloseComponentOutside(false);

  return (
    <div ref={ref}>
      <div className={styles['options-container']}>
        <button type="button" className={styles['options-button']} onClick={() => setIsComponentVisible(!isComponentVisible)}>
          {icon || optionsVertical}
        </button>
        {isComponentVisible && (
          <OptionsMenu
            options={options}
            classes={[]}
          />
        )}
      </div>
    </div>
  );
}

export default Options;
