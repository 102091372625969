import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { DateSelect } from '@veo/web-design-system';
import { AppDispatch } from '../../redux/store';
import { getCurrentLeagueSelector } from '../../redux/features/leagueSlice';
import {
  fetchLeagueRecordings,
  fetchMoreLeagueRecordings,
  getCurrentLeagueRecordingsSelector,
} from '../../redux/features/recordingsSlice';

import ActionButton from '../../components/Button/ActionButton';
import {
  adminIcon, filterIcon, optionsHorizontal, searchIcon, uploadButton,
} from '../../icons';
import styles from './Recordings.module.scss';
import RecordingsList from '../../components/Lists/RecordingsList/RecordingsList';
import LeagueNavigationContainer from '../../containers/League/LeagueNavigationContainer';
import LeagueContainer from '../../containers/League/LeagueContainer';
import MyImage from '../../assets/Thumbnail.png';
import { getUserSelector } from '../../redux/features/userSlice';
import Dropdown from '../../components/Dropdown/Dropdown';

import { canAccessBackOffice, canUploadRecording, isElevatedUser } from '../../auth/permissions';
import useInfiniteScroll from '../../hooks/useInfiniteScroll';
import Spinner from '../../components/Spinner/Spinner';
import { APIStatus } from '../../enums/APIStatus';
import { fetchTeams, getTeamsSelector } from '../../redux/features/teamsSlice';
import { Role } from '../../enums/Role';

const Recordings = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { user } = useSelector(getUserSelector);
  const currentLeague = useSelector(getCurrentLeagueSelector);
  const teams = useSelector(getTeamsSelector);
  const infiniteScollTrigger = useRef(null);
  const inputFile = useRef<HTMLInputElement | null>(null);
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [filteredTeam, setFilteredTeam] = useState<string | undefined>(undefined);
  const [dateRangeFilter, setDateRangeFilter] = useState<string[]>([]);
  const filterTimeout = useRef<number | null>(null);

  const fetchOlderRecordings = () => {
    // TODO for now we use an env to be able to switch back to something
    // that has been working atleast, although with issues
    let paginationObj = {};
    if (process.env.REACT_APP_USE_CURSOR_PAGINATION === 'true') {
      paginationObj = { cursor: 'after' };
    } else {
      paginationObj = { page: 'next' };
    }
    dispatch(fetchMoreLeagueRecordings({
      leagueId: currentLeague?.public_identifier!, ...paginationObj,
    }));
  };

  const [currentLeagueRecordings, infiniteScrollStatus] = useInfiniteScroll(
    getCurrentLeagueRecordingsSelector,
    fetchOlderRecordings,
    infiniteScollTrigger,
    [currentLeague],
  );

  useEffect(() => {
    if (currentLeague?.public_identifier) {
      dispatch(fetchLeagueRecordings({ leagueId: currentLeague.public_identifier }));
      dispatch(fetchTeams(currentLeague.public_identifier));
    }
    setFilteredTeam(undefined);
  }, [currentLeague]);

  useEffect(() => {
    if (currentLeague?.public_identifier) {
      if (filterTimeout.current) {
        clearTimeout(filterTimeout.current);
      }

      filterTimeout.current = window.setTimeout(() => {
        dispatch(fetchLeagueRecordings({
          leagueId: currentLeague.public_identifier,
          filteredTeam,
          dateRange: dateRangeFilter.length === 2
            ? { start: dateRangeFilter[0], end: dateRangeFilter[1] }
            : undefined,
        }));
      }, 500);
    }
  }, [filteredTeam, dateRangeFilter]);

  const onChangeHandler = async (event: any) => {
    const file: File = event.target.files[0];
    navigate('/upload', {
      state: {
        file,
        leaguePublicId: currentLeague?.public_identifier,
      },
    });
  };

  const formatDate = (date: string) => {
    const d = new Date(date);
    const year = `${d.getUTCFullYear()}`.slice(-2);
    const month = `${d.getUTCMonth() + 1}`.padStart(2, '0');
    const day = `${d.getUTCDate()}`.padStart(2, '0');
    return `${month}/${day}/${year}`;
  };

  const formatDuration = (duration: number) => {
    if (duration == null) {
      return '';
    }
    return new Date(duration * 1000)
      .toISOString()
      .substring(11, 19);
  };

  const recordings = currentLeagueRecordings?.map((rec) => ({
    id: rec.public_identifier,
    duration: formatDuration(rec.duration),
    homeScore: rec.team_home_score,
    awayScore: rec.team_away_score,
    homeTeam: rec.home_team_name,
    awayTeam: rec.away_team_name,
    homeTeamAbbreviation: rec.home_team_abbreviation,
    awayTeamAbbreviation: rec.away_team_abbreviation,
    thumbnail: MyImage,
    date: formatDate(rec.recorded_date),
    url: rec.url,
    veoId: rec.veo_id,
  })) || [];

  const navigateToBackOffice = () => {
    if (isElevatedUser(user)) {
      navigate('/cockpit');
      return;
    }

    if (currentLeague?.permission === Role.LEAGUE_OWNER) {
      navigate(`/cockpit/${currentLeague.public_identifier}/edit`);
    }
  };

  const teamFilterOptions = teams.map((team) => ({
    label: team.team_name,
    id: team.team_name,
  }));

  return (
    <LeagueContainer>
      <div className={styles['recording-container']}>
        <nav className={styles['nav-wrapper']}>
          <div className={styles.nav}>
            <input type="file" className={styles['video-input']} ref={inputFile} accept="video/mp4" onChange={onChangeHandler} />
            <LeagueNavigationContainer />
            <div className={styles['menu-header']}>
              <button className={styles['options-horizontal-icon']} type="button">
                <label htmlFor="menu-toggle">{optionsHorizontal}</label>
              </button>
              <input id="menu-toggle" type="checkbox" className={styles.toggle} />
              <div className={styles['button-wrapper']}>
                <div className={styles['action-buttons']}>
                  {
                    canAccessBackOffice({
                      user,
                      role: currentLeague?.permission ?? Role.VIEWER,
                    })
                    && <ActionButton onClick={navigateToBackOffice} icon={adminIcon} label="Admin" />
                  }
                  <ActionButton
                    icon={filterIcon}
                    onClick={() => {
                      if (isFilterVisible) {
                        setFilteredTeam(undefined);
                      }
                      setIsFilterVisible(!isFilterVisible);
                    }}
                    label="Filters"
                  />
                  {
                    canUploadRecording(currentLeague?.permission)
                    && (
                      <ActionButton
                        icon={uploadButton}
                        onClick={() => inputFile.current?.click()}
                        label="Upload"
                      />
                    )
                  }
                </div>
              </div>
            </div>
          </div>
        </nav>
        {isFilterVisible && (
          <div className={styles.filter}>
            <div className={styles.flexbox}>
              <div className={styles['filter-dropdown']}>
                <Dropdown
                  placeholder="Search by team name"
                  clearSelectedOption={filteredTeam === ''}
                  onOptionClicked={(team) => {
                    setFilteredTeam(team);
                  }}
                  onOptionChanged={(team) => {
                    setFilteredTeam(team);
                  }}
                  options={teamFilterOptions}
                  icon={searchIcon}
                />
              </div>
              <div className={styles['clear-button']}>
                <button className={styles.button} type="button" onClick={() => setFilteredTeam(undefined)}>
                  Clear
                </button>
              </div>
            </div>
            <div className={styles.flexbox}>
              <DateSelect
                onUpdate={setDateRangeFilter}
                selectRange
                values={dateRangeFilter}
                updateOnChange
              />
              <div className={styles['clear-button']}>
                <button className={styles.button} type="button" onClick={() => setDateRangeFilter([])}>
                  Clear
                </button>
              </div>
            </div>
          </div>
        )}
        <main className={styles['main-container']}>
          <RecordingsList items={recordings} />
          {filteredTeam === '' && (
            <>
              <div ref={infiniteScollTrigger} />
              {infiniteScrollStatus === APIStatus.PENDING && (
                <div className={styles.spinner}><Spinner float={false} /></div>
              )}
            </>
          )}
        </main>
      </div>
    </LeagueContainer>
  );
};

export default Recordings;
