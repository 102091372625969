const removeAppendingSlash = (url: string) => {
  if (url && url[url.length - 1] === '/') return url.substring(0, url.length - 1);
  return url;
};

const ensureAppendingSlash = (url: string) => {
  if (url && url[url.length - 1] !== '/') {
    url += '/';
  }
  return url;
};

export const ZOLA_APP_URL = removeAppendingSlash(process.env.REACT_APP_ZOLA_APP_URL!);

export const LEX_API_BASE_URL = ensureAppendingSlash(process.env.REACT_APP_LEX_API_URL!);

export const LEX_UPLOADS_CDN_BASE_URL = process.env.REACT_APP_LEX_UPLOADS_CDN_BASE_URL ?? '';
export const VEO_CDN_BASE_URL = 'https://download.veocdn.com';

export const FILE_CHUNK_SIZE = 1000000000; // 1 GB

export const MAX_UPLOAD_SIZE = 12000000000; // 12 GB

export const HEADERS = {
  VEO_USER_ID: 'Veo-User-Id',
};

export const API_KEY_LABEL = 'lex_key';

export const SEGMENT_WRITE_KEY = process.env.REACT_APP_SEGMENT_WRITE_KEY ?? '';
