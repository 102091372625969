/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import styles from './Dropdown.module.scss';
import { chevronDown, chevronUp } from '../../icons';
import CloseComponentOutside from '../Wrapper/CloseComponentOutside';

interface Option {
  label: string;
  id: any;
}

type Props = {
  placeholder: string;
  onOptionClicked: (id: string) => void;
  options: Option[];
  clearSelectedOption?: boolean;
  icon?: JSX.Element;
  onOptionChanged?: (value: string) => void;
};

function Dropdown({
  placeholder, options, onOptionClicked, clearSelectedOption, icon, onOptionChanged,
}: Props) {
  const [selectedOption, setSelectedOption] = useState<Option>({ label: '', id: '' });
  const { ref, isComponentVisible, setIsComponentVisible } = CloseComponentOutside(false);
  const [filter, setFilter] = useState('');

  useEffect(() => {
    if (clearSelectedOption) {
      setSelectedOption({ label: '', id: '' });
      setFilter('');
    }
  }, [clearSelectedOption]);

  const handleToggle = () => {
    setIsComponentVisible(!isComponentVisible);
  };

  const onClick = (option: Option) => () => {
    setSelectedOption(option);
    setIsComponentVisible(false);
    onOptionClicked(option.id);
  };

  return (
    <div className={styles['dropdown-container']} ref={ref}>
      <div
        className={styles['dropdown-select']}
        onClick={handleToggle}
      >
        <div className={styles['dropdown-icon-text']}>
          {icon}
          <div className={styles['dropdown-text']}>
            {' '}
            {selectedOption.label || filter || placeholder}
            {' '}
          </div>
        </div>
        <input
          autoComplete="off"
          id="filter"
          name="filter"
          type="search"
          value={filter}
          onChange={(event) => {
            setFilter(event.target.value);
            if (onOptionChanged) {
              onOptionChanged(event.target.value);
            }
          }}
          className={styles['input-hidden']}
        />

        <div className={styles['dropdown-chevron-icon']}>
          {isComponentVisible ? chevronUp : chevronDown}
        </div>
      </div>
      {isComponentVisible && (
        <ul className={styles['dropdown-list-container']}>
          {options.filter((option) => option.label.toLowerCase().includes(
            filter.toLocaleLowerCase(),
          ))
            .map((option) => (
              <button
                type="button"
                className={styles['dropdown-list']}
                onClick={onClick(option)}
                key={option.id}
              >
                {option.label}
              </button>
            ))}
        </ul>
      )}
    </div>
  );
}

export default Dropdown;
