import { User } from '../types/user.type';

import { Role } from '../enums/Role';
import { PermissionChecker, PermissionsGetter } from '../types/auth.type';

export const getPermissions: PermissionsGetter = (role) => {
  switch (role) {
    case Role.LEAGUE_OWNER:
      return [
        'recording.can_delete',
        'recording.can_edit',
        'recording.can_upload',
        'member.can_edit',
        'member.can_delete',
      ];
    case Role.ADMIN:
      return [
        'recording.can_delete',
        'recording.can_edit',
        'recording.can_upload',
        'member.can_edit',
        'member.can_delete',
      ];
    case Role.EDITOR:
      return [
        'recording.can_delete',
        'recording.can_edit',
        'recording.can_upload',
      ];
    case Role.VIEWER:
      return [];
    default:
      return [];
  }
};

export const hasPermission: PermissionChecker = (role, action) => (
  getPermissions(role).includes(action)
);

// Recording-related permissions
export const canUploadRecording = (role: Role = Role.VIEWER) => (
  hasPermission(role, 'recording.can_upload')
);

export const canEditRecording = (role: Role = Role.VIEWER) => (
  hasPermission(role, 'recording.can_edit')
);

export const canDeleteRecording = (role: Role = Role.VIEWER) => (
  hasPermission(role, 'recording.can_delete')
);

export const canManageRecording = (role: Role = Role.VIEWER) => (
  canEditRecording(role) || canDeleteRecording(role)
);

// Members related
export const canEditMember = (role: Role = Role.VIEWER) => (
  hasPermission(role, 'member.can_edit')
);

export const canDeleteMember = (role: Role = Role.VIEWER) => (
  hasPermission(role, 'member.can_delete')
);

export const canManageMember = (role: Role = Role.VIEWER) => (
  canEditMember(role) || canDeleteMember(role)
);

export const isElevatedUser = (user: User | undefined) => (
  user?.is_super_user || user?.is_backoffice_admin
);
// System permissions
export const canAccessBackOffice = ({ user, role }: { user: User | undefined, role: Role }) => (
  isElevatedUser(user) || role === Role.LEAGUE_OWNER
);
