import React from 'react';
import styles from './Chip.module.scss';

type Props = {
  tagText: string;
  color?: 'green';
  children?: JSX.Element;
};

function Chip({ tagText, color, children }: Props) {
  return (
    <div className={[styles['chip-container'], color && styles[color]].join(' ')}>
      {tagText}
      {children}
    </div>
  );
}

export default Chip;
