import axios from '../../privateApi';

import { CreateLeagueType, LeagueOwner, LeagueUpdate } from '../../../types/league.type';

/*
This will go through the privateApi instance of Axios, hence a user id is automatically sent.
Therefore we do not need to specify the any queryParams, as this will return the leagues the
user is a member of.
*/

// TODO: add federation id

export const getLeague = (leagueId: string) => axios.get(`leagues/${leagueId}?include=teams&include=teams.members`);

export const getLeagues = (userId: string) => axios.get(`leagues?user_id=${userId}`);

// TODO: this is mainly used on the admin page. Should fetch all that the user is a director of
export const getAllLeagues = () => axios.get('leagues');

export const createLeague = (leagueData: CreateLeagueType) => axios.post('leagues', leagueData);

export const updateLeague = (leagueId: string, data: LeagueUpdate) => axios.put(`leagues/${leagueId}`, data);

export const createLeagueOwner = (leagueId: string, data: LeagueOwner) => axios.post(`leagues/${leagueId}/owners`, data);

export const deleteLeagueOwner = (leagueId: string, userId: string) => axios.delete(`leagues/${leagueId}/owners/${userId}`);

export const deleteLeague = (leagueId: string) => axios.delete(`leagues/${leagueId}`);

export const getLeagueCrestUploadUrl = (leagueId: string, fileExtension: string) => (
  axios.post('league-crest-upload-url', { league_id: leagueId, file_extension: fileExtension })
);

export const uploadLeagueCrest = (crest: File, url: string) => axios.put(url, crest);
