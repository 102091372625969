import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import App from './App';
import store from './redux/store';
import { RollbarProvider } from './lib/RollbarProvider';

const container = document.getElementById('lex-app');
const root = createRoot(container!);

root.render(
  <RollbarProvider>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </RollbarProvider>,
);
