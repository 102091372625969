import React, {
  useState, useEffect, Dispatch, SetStateAction,
} from 'react';

import styles from './ProgressBar.module.scss';

type Props = {
  onMount: (p: Dispatch<SetStateAction<number>>) => any;
  fileName: string;
};

function Child({ onMount, fileName }: Props) {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    onMount(setProgress);
  }, [onMount]);

  return (
    <div className={styles['uploading-bar-outer']}>
      <div className={styles['uploading-bar-inner']}>
        <div className={styles['progress-bar-content']}>
          <div>
            <span className={styles['weight-600']}>Uploading video: </span>
            <span>
              {fileName}
              {' '}
            </span>
          </div>
          <span>
            <span className={styles['weight-600']}>
              {' '}
              {progress}
              % Complete
            </span>
          </span>
        </div>
        <div className={styles['progress-bar']}>
          <div
            className={styles['progress-bar-loaded']}
            style={{
              width: `${progress}%`,
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default Child;
