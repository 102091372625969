import React from 'react';

import styles from './ListHeader.module.scss';

type Props = {
  labels: [string, string, string, string];
};

const ListHeader = ({
  labels,
}: Props) => (
  <div>
    <header className={styles['list-wrapper']}>
      {/* eslint-disable-next-line react/no-array-index-key */}
      {labels.map((label, index) => <div key={index}>{label}</div>)}
      <div />
      <div />
    </header>
  </div>
);

export default ListHeader;
