import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { MessageType } from '../../../enums/MessageType';
import {
  closeButton, checkCircle, alertIcon, infoIcon,
} from '../../../icons';
import { clearMemberMessage } from '../../../redux/features/membersSlice';
import { clearRecordingMessage } from '../../../redux/features/recordingsSlice';
import styles from './SuccessModal.module.scss';

type Props = {
  title?: string,
  message?: string;
  type: MessageType,
  icon?: JSX.Element;
};

const SuccessModal = ({
  title, message, type, icon,
}: Props) => {
  const dispatch = useDispatch();
  useEffect(() => {
    setTimeout(() => {
      dispatch(clearRecordingMessage());
      dispatch(clearMemberMessage());
    }, 3000);
  }, []);

  let successModalStyle: string;
  let successBorderColor: string;
  let successModalTitleStyle: string;
  let iconColor: string;

  if (type === MessageType.DELETED) {
    successModalStyle = styles['success-modal'];
    successBorderColor = `${styles['success-modal-border']} ${styles['border-deleted']} `;
    successModalTitleStyle = `${styles['success-modal-title']} ${styles['title-deleted']} `;
    icon = infoIcon;
    iconColor = styles['delete-icon'];
  } else if (type === MessageType.ERROR) {
    successModalStyle = styles['success-modal'];
    successBorderColor = `${styles['success-modal-border']} ${styles['border-error']} `;
    successModalTitleStyle = `${styles['success-modal-title']} ${styles['title-error']} `;
    icon = alertIcon;
    iconColor = styles['error-icon'];
  } else {
    successModalStyle = styles['success-modal'];
    successBorderColor = `${styles['success-modal-border']} ${styles['border-success']} `;
    successModalTitleStyle = `${styles['success-modal-title']} ${styles['title-success']} `;
    icon = checkCircle;
    iconColor = styles['success-icon'];
  }

  return (
    <div className={styles['success-modal-wrapper']}>
      <div className={successBorderColor}>.</div>
      <div className={successModalStyle}>
        <div className={styles['content-icon-wrapper']}>
          <div className={iconColor}>
            {icon}
          </div>
          <div className={styles['content-wrapper']}>
            <div className={successModalTitleStyle}>
              {title}
            </div>
            <div className={styles['success-message']}>
              {message}
            </div>
          </div>
        </div>
        <button
          className={styles['icon-button']}
          type="button"
          onClick={() => {
            dispatch(clearRecordingMessage());
            dispatch(clearMemberMessage());
          }}
        >
          {closeButton}
        </button>
      </div>
    </div>
  );
};

export default SuccessModal;
