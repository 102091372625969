import React, { useState } from 'react';
import { League, LeagueOwner } from '../../../types/league.type';
import styles from './Form.module.scss';
import leagueOwnerRowStyles from './LeagueOwnerListItem.module.scss';
import { LeagueOwnerListItem } from './LeagueOwnerListItem';
import Button from '../../Button/Button';
import { ButtonType } from '../../../enums/ButtonType';
import * as privateApi from '../../../apis/privateApi';

type LeagueFormProps = {
  onChange: (league: League | string) => void;
  onLeagueOwnerAdd: () => void;
  onLeagueOwnerRemove: (owner: LeagueOwner) => void;
  league: League;
  leagueOwners: LeagueOwner[];
  onLeagueOwnerChange: (owner: LeagueOwner) => void;
  disabled?: boolean;
  refetchData?: () => Promise<void>;
};

type FormValidity = {
  name?: ValidityState;
};

const LeagueForm = ({
  league, leagueOwners, onLeagueOwnerAdd, onLeagueOwnerRemove,
  onLeagueOwnerChange, onChange, disabled, refetchData,
}: LeagueFormProps) => {
  const [validity, setValidity] = useState<FormValidity>({});

  const checkValidity = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, validity: newValidity } = event.target;
    setValidity({ ...validity, [name]: newValidity });
  };

  const onFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    checkValidity(event);
    if ((league as League).public_identifier) {
      onChange({
        ...league as League,
        [name]: value,
      });
    } else {
      onChange(value);
    }
  };

  const handleDelete = async (owner: LeagueOwner) => {
    if (!owner.id) {
      onLeagueOwnerRemove?.(owner);
      return;
    }

    if (!window.confirm('Are you sure you want to delete this admin?')) {
      return;
    }

    await privateApi.deleteLeagueOwner(league.public_identifier, owner.id);
    await refetchData?.();
  };

  const nameError = (validity.name?.valueMissing) ? 'The name is required' : '';

  return (
    <div className={styles.container} style={{ gap: '3rem' }}>
      <div className={styles.row}>
        <div>
          <div className={styles.label}>League Name</div>
          <div>
            <input
              type="text"
              name="name"
              placeholder="League name"
              onChange={onFieldChange}
              value={league?.name}
              required
              disabled={disabled}
            />
          </div>
          <div className={styles['error-feedback']}>{nameError}</div>
        </div>
      </div>
      <div className={styles.container}>
        <div>
          <h2 className={styles.subheading}>League Admins</h2>
          <div className={leagueOwnerRowStyles.row}>
            <div className={styles.label}>Email</div>
            <div className={styles.label}>Role</div>
          </div>
          <div className={styles.container}>
            {leagueOwners.map((owner) => (
              <LeagueOwnerListItem
                key={owner.id}
                owner={owner}
                disabled={owner.id !== ''}
                onChange={onLeagueOwnerChange}
                onDelete={handleDelete}
              />
            ))}
          </div>
        </div>
        <Button
          label="Add Admin"
          buttonType={ButtonType.BACKOFFICE}
          onClick={onLeagueOwnerAdd}
        />
      </div>
    </div>
  );
};

export default LeagueForm;
