import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getTeams, getUserTeams } from '../../apis/privateApi';
import { APIStatus } from '../../enums/APIStatus';
import { Team } from '../../types/team.type';
import { IRootState } from '../store';

const initialState = {
  status: APIStatus.IDLE,
  teams: [] as Team[],
};

export const fetchTeams = createAsyncThunk('teams', async (leagueId: string) => getTeams(leagueId)
  .then((response) => response.data)
  .catch((error) => {
    throw error;
  }));

export const fetchUserTeams = createAsyncThunk('userTeams', async ({ leagueId, userId }: { leagueId: string, userId: string }) => getUserTeams(leagueId, userId).then((response) => response.data));

const teamsSlice = createSlice({
  name: 'teams',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTeams.pending, (state) => {
        state.status = APIStatus.PENDING;
      })
      .addCase(fetchTeams.fulfilled, (state, action) => {
        state.status = APIStatus.FULFILLED;
        state.teams = action.payload.data;
      })
      .addCase(fetchTeams.rejected, (state) => {
        state.status = APIStatus.REJECTED;
      })
      .addCase(fetchUserTeams.pending, (state) => {
        state.status = APIStatus.PENDING;
      })
      .addCase(fetchUserTeams.fulfilled, (state, action) => {
        state.status = APIStatus.FULFILLED;
        state.teams = action.payload.data;
      });
  },
});

export const getUserTeamsSelector = (state: IRootState) => state.teamsReducer;
export const getTeamsSelector = (state: IRootState) => state.teamsReducer.teams;
export default teamsSlice.reducer;
