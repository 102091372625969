import React, { useState } from 'react';

import styles from './RecordingsList.module.scss';
import VideoPlayerModal from '../../Modals/VideoPlayer/VideoPlayerModal';
import RecordingsListItemOptions from './RecordingsListItemOptions';
import { EventName, telemetry } from '../../../telemetry/telemetry';

type Props = {
  id: string,
  duration: string;
  homeTeam: string,
  homeTeamAbbreviation: string,
  awayTeam: string,
  awayTeamAbbreviation: string,
  homeScore: number,
  awayScore: number,
  date: any,
  thumbnail: string,
  url: string,
  veoId: string,
};

function RecordingsListItem({
  id,
  duration,
  homeTeam,
  homeTeamAbbreviation,
  awayTeam,
  awayTeamAbbreviation,
  homeScore,
  awayScore,
  date,
  thumbnail,
  url,
  veoId,
}: Props) {
  const [isMatchSelected, setIsMatchSelected] = useState(false);

  const recordingTitle = `${homeTeam} vs. ${awayTeam}`;
  const recordingScores = `${homeTeamAbbreviation} ${homeScore} - ${awayScore} ${awayTeamAbbreviation}`;

  const onClickHandler = async () => {
    setIsMatchSelected(true);
    await telemetry.track(EventName.LEX_RECORDING_VIEWED);
  };

  const onCloseVideoPlayer = () => {
    setIsMatchSelected(false);
  };

  return (
    <div>
      <main>
        <div className={styles['recording-list']} key={id}>
          <div
            className={styles['recording-list-clickable']}
            role="button"
            onKeyDown={() => { }}
            tabIndex={0}
            onClick={onClickHandler}
          >
            <div className={styles['recording-list-clickable-thumbnail']}>
              <div className={styles['recording-list-clickable']}>
                <img src={thumbnail} alt="recording" />
              </div>
            </div>
            <div className={styles['recording-list-clickable-data']}>
              <div className={styles['recording-list-clickable-data-match']}>
                <div className={styles['recording-list-clickable-data-match-title']}>
                  {recordingTitle}
                </div>
                <div className={styles['recording-list-clickable-data-match-duration']}>
                  {duration}
                </div>
              </div>
              <div className={styles['recording-list-clickable-data-team']}>
                <div className={styles['recording-list-clickable-data-team-score']}>
                  {recordingScores}
                </div>
                <div className={styles['recording-list-clickable-data-team-date']}>
                  {date}
                </div>
              </div>
            </div>
          </div>
          <div className={styles['recording-list-options']}>
            <RecordingsListItemOptions
              recordingId={id}
              recordingTitle={recordingTitle}
              recordingThumbnail={thumbnail}
              recordingDuration={duration}
              veoId={veoId}
            />
          </div>
        </div>
      </main>

      {isMatchSelected && (
        <VideoPlayerModal
          recordingTitle={recordingTitle}
          date={date}
          teamHomeAbbreviation={homeTeamAbbreviation}
          teamAwayAbbreviation={awayTeamAbbreviation}
          teamHomeScore={homeScore}
          teamAwayScore={awayScore}
          onCloseModal={onCloseVideoPlayer}
          recordingUrl={url}
          recordingId={id}
        />
      )}

    </div>
  );
}

export default RecordingsListItem;
