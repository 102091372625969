import React from 'react';
import { Provider, ErrorBoundary } from '@rollbar/react';
import Rollbar from 'rollbar';

type RollbarProviderProps = {
  children: React.ReactNode;
};

const rollbarConfig: Rollbar.Configuration = {
  accessToken: process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN || '',
  environment: process.env.REACT_APP_ROLLBAR_ENVIRONMENT || 'development',
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    environment: process.env.REACT_APP_ROLLBAR_ENVIRONMENT || 'development',
    client: {
      javascript: {
        source_map_enabled: true,
        code_version: process.env.REACT_APP_VERSION,
        guess_uncaught_frames: true,
      },
    },
    server: {
      branch: 'main',
    },
  },
};

export function RollbarProvider({ children }: RollbarProviderProps) {
  return (
    <Provider config={rollbarConfig}>
      <ErrorBoundary>
        {children}
      </ErrorBoundary>
    </Provider>
  );
}
