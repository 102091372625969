import { Role } from '../../../../../enums/Role';
import axios from '../../../../privateApi';

export const getTeamMembers = (leagueId: string, teamId: string, permission?: Role) => (
  axios.get(`leagues/${leagueId}/teams/${teamId}/members${(permission) ? `?permission=${permission}` : ''}`)
);

export const updateInvitedMember = (
  leagueId: string,
  teamId: string,
  memberId: string,
  permission: string,
) => (
  axios.put(`leagues/${leagueId}/teams/${teamId}/members/${memberId}?backoffice`, { permission })
);

export const updateTeamMember = (
  leagueId: string,
  teamId: string,
  userId: string,
  permission: string,
) => (
  axios.put(`leagues/${leagueId}/teams/${teamId}/members/${userId}`, { permission })
);

export const deleteTeamMember = (leagueId: string, teamId: string, memberId: string) => (
  axios.delete(`leagues/${leagueId}/teams/${teamId}/members/${memberId}`)
);

export const deleteInvitedMember = (leagueId: string, teamId: string, memberId: string) => (
  axios.delete(`leagues/${leagueId}/teams/${teamId}/members/${memberId}?invited`)
);
