import React, { useEffect, useState } from 'react';
import {
  Route, Routes,
} from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';

import './assets/fonts/fonts.css';

import Layout from './components/Layout/Layout';
import Recordings from './routes/recordings/Recordings';
import Team from './routes/team/Team';
import Upload from './routes/upload/Upload';
import ErrorFallback from './ErrorFallback';
import Members from './routes/members/Members';
import UserLoaderContainer from './containers/User/UserLoaderContainer';

import './App.scss';
import BackOfficeCreateLeague from './components/BackOffice/BackOfficeCreateLeague';
import BackOfficeEditLeague from './components/BackOffice/BackOfficeEditLeague';
import BackOfficeDashboard from './components/BackOffice/BackOfficeDashboard';

import { lexReady } from './utils/zola_agent';

import { HEADERS, API_KEY_LABEL, ZOLA_APP_URL } from './settings';
import { usePageViewEffect } from './hooks/usePageView';

function App() {
  const [userId, setUserId] = useState('');

  const zolaEventListener = (e: any) => {
    if (e.origin !== ZOLA_APP_URL) return;

    const data = JSON.parse(e.data);
    if (data.user_id) {
      setUserId(data.user_id);
    }

    if (data.token) {
      sessionStorage.setItem(API_KEY_LABEL, data.token);
    }
  };

  useEffect(() => {
    if (window.location !== window.parent.location) {
      // App loaded in the iFrame, ie from within Zola

      // Listen to messages from Zola
      window.addEventListener('message', zolaEventListener, false);

      // Tell Zola the LexApp is ready
      lexReady();
    } else if (process.env.NODE_ENV === 'development' && process.env.REACT_APP_USER_PUBLIC_ID) {
      // If local development is undergoing
      setUserId(process.env.REACT_APP_USER_PUBLIC_ID);
    } else {
      // if app is loaded by itself, i.e. not from within Zola
      window.location.href = ZOLA_APP_URL;
    }
  }, []);

  useEffect(() => {
    if (userId) {
      sessionStorage.setItem(HEADERS.VEO_USER_ID, userId);
    }
  }, [userId]);

  usePageViewEffect();

  const routes = (
    <Routes>
      <Route path="/" element={<Recordings />} />
      <Route path="/members" element={<Members />} />
      <Route path="/team" element={<Team />} />
      <Route path="/upload" element={<Upload />} />
      <Route path="/cockpit" element={<BackOfficeDashboard />} />
      <Route path="/cockpit/create" element={<BackOfficeCreateLeague />} />
      <Route path="/cockpit/:leagueId/edit" element={<BackOfficeEditLeague />} />
    </Routes>
  );

  return (
    <Layout>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <UserLoaderContainer userId={userId}>
          {routes}
        </UserLoaderContainer>
      </ErrorBoundary>
    </Layout>
  );
}

export default App;
