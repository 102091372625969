import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { addMember, optionsHorizontal } from '../../icons';
import ActionButton from '../../components/Button/ActionButton';
import styles from './Members.module.scss';
// import Pagination from '../../components/Pagination/Pagination';
import MembersList from '../../components/Lists/MembersList/MembersList';
import {
  fetchLeagueMembers, getLeagueMembersSelector,
} from '../../redux/features/membersSlice';
import LeagueNavigationContainer from '../../containers/League/LeagueNavigationContainer';
import { Role } from '../../enums/Role';
import LeagueContainer from '../../containers/League/LeagueContainer';
import { getCurrentLeagueSelector } from '../../redux/features/leagueSlice';
import { AppDispatch } from '../../redux/store';
import InviteMembersModal from '../../components/Modals/InviteMembers/InviteMembersModal';

function Members() {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const currentLeague = useSelector(getCurrentLeagueSelector);
  const members = useSelector(getLeagueMembersSelector);

  const [inviteMembersModal, setInviteMembersModal] = useState(false);

  // User permissions
  const permission = currentLeague?.permission;
  const canView = permission === Role.ADMIN || permission === Role.EDITOR;
  const canInvite = permission === Role.ADMIN;

  useEffect(() => {
    if (currentLeague) {
      dispatch(fetchLeagueMembers(currentLeague.public_identifier));
    }
  }, [currentLeague]);

  if (!canView) {
    navigate('/');
  }

  const cancelMembersInviteModal = () => {
    setInviteMembersModal(false);
  };

  return (
    <LeagueContainer>
      <div className={styles['members-container']}>
        <nav className={styles['nav-wrapper']}>
          <div className={styles.nav}>
            <LeagueNavigationContainer />
            <div className={styles['menu-header']}>
              <button className={styles['options-horizontal-icon']} type="button">
                <label htmlFor="menu-toggle">{optionsHorizontal}</label>
              </button>
              <input id="menu-toggle" type="checkbox" className={styles.toggle} />
              <div className={styles['button-wrapper']}>
                <div className={styles['action-buttons']}>
                  {canInvite && (
                    <ActionButton
                      icon={addMember}
                      onClick={() => setInviteMembersModal(!inviteMembersModal)}
                      label="Invite member"
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </nav>
        <main className={styles['main-container']}>
          <MembersList members={members} />
        </main>
      </div>
      <div />
      {inviteMembersModal && (
        <InviteMembersModal
          negativeActionClicked={cancelMembersInviteModal}
        />
      )}
      {/* <Pagination currentPage={3} lastPage={9} pagesShown={3} onPageClick={() => { }} /> */}
    </LeagueContainer>

  );
}

export default Members;
