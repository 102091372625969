import { ZOLA_APP_URL } from '../settings';

export const lexReady = () => {
  window.parent.postMessage(JSON.stringify({ isLoaded: true }), ZOLA_APP_URL);
};

export const lexPlayerStatus = (isPlaying: boolean) => {
  window.parent.postMessage(JSON.stringify({ isVideoPlaying: String(isPlaying) }), ZOLA_APP_URL);
};

export const isLexModalOpen = (isOpen: boolean) => {
  window.parent.postMessage(JSON.stringify({ isModalOpen: String(isOpen) }), ZOLA_APP_URL);
};
