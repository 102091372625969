import React from 'react';

import styles from './Layout.module.scss';
import Message from '../Messages/Message';

type Props = {
  children: JSX.Element,
};

function Layout({ children }: Props) {
  return (
    <div className={styles.layout}>
      <Message />
      <main className={styles.content}>{children}</main>
    </div>
  );
}

export default Layout;
