import React from 'react';
import { useSelector } from 'react-redux';
import { getUserSelector } from '../../../redux/features/userSlice';
import ListHeader from '../ListHeader';
import { Member } from '../../../types/member.type';
import MembersListItem from './MembersListItem';
import { Role } from '../../../enums/Role';

type Props = {
  members: Member[];
};

function MembersList({ members }: Props) {
  // Permissions
  const user = useSelector(getUserSelector);
  const teamRoles = new Map(members.filter((member) => (
    member.user?.veo_id === user.user?.veo_id
  )).map((member) => [member.team_id, member.permission]));
  const canEdit = (teamId: string) => teamRoles.get(teamId) === Role.ADMIN;
  const canView = (teamId: string) => (
    teamRoles.get(teamId) === Role.ADMIN
    || teamRoles.get(teamId) === Role.EDITOR
  );

  const membersRows = members.map((member) => {
    const keyPrefix = member.user_id || member.user?.email;
    const key = keyPrefix + member.team_id;
    return (
      canView(member.team_id) && (
        <MembersListItem
          member={member}
          showOptions={canEdit(member.team_id)}
          key={key}
        />
      )
    );
  });

  return (
    <div>
      <ListHeader
        labels={['Name', 'Email', 'Team', 'Permission']}
      />
      {membersRows}
    </div>
  );
}

export default MembersList;
