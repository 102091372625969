import LinkHeader from 'http-link-header';
import { PaginationPages } from '../../types/pagination.type';

const fromLinkHeader = (value: string = ''): PaginationPages => {
  const link = LinkHeader.parse(value);
  const [first] = link.rel('first');
  const [last] = link.rel('last');
  const [prev] = link.rel('prev');
  const [next] = link.rel('next');

  return {
    first: first?.uri,
    last: last?.uri,
    prev: prev?.uri,
    next: next?.uri,
  };
};

export default {
  fromLinkHeader,
}
