import React from 'react';
import styles from './ArrowDownTooltip.module.scss';

type Props = {
  tooltipText: string;
};

function ArrowDownTooltip({ tooltipText }: Props) {
  return (
    <div className={styles['arrow-tooltip']}>{tooltipText}</div>
  );
}

export default ArrowDownTooltip;
