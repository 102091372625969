import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getUser } from '../../apis/requests/users/users';
import { User } from '../../types/user.type';
import { APIStatus } from '../../enums/APIStatus';
import { IRootState } from '../store';

type State = {
  status: APIStatus,
  user: User | undefined,
};

const initialState: State = {
  status: APIStatus.FULFILLED,
  user: {} as User,
};

export const fetchUser = createAsyncThunk('user', async (veoId: string) => getUser(veoId)
  .then((response) => response.data));

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUser.pending, (state) => {
        state.status = APIStatus.PENDING;
      })
      .addCase(fetchUser.fulfilled, (state, action) => {
        if (action.payload) {
          state.status = APIStatus.FULFILLED;
          state.user = action.payload.data;
        } else {
          state.status = APIStatus.REJECTED;
        }
      })
      .addCase(fetchUser.rejected, (state) => {
        state.status = APIStatus.REJECTED;
      });
  },
});

export const getUserSelector = (state: IRootState) => state.userReducer;
export default userSlice.reducer;
