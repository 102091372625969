import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { useRollbarPerson } from '@rollbar/react';
import { fetchUser, getUserSelector } from '../../redux/features/userSlice';
import { APIStatus } from '../../enums/APIStatus';
import { AppDispatch } from '../../redux/store';
import Spinner from '../../components/Spinner/Spinner';
import ErrorPage from '../../components/ErrorPage/ErrorPage';

type Props = {
  children: React.ReactNode;
  // TODO: check for a valid UUID
  userId?: string;
};

function UserLoaderContainer({ children, userId }: Props) {
  const dispatch = useDispatch<AppDispatch>();

  const { user, status } = useSelector(getUserSelector);

  useRollbarPerson({
    id: userId,
    email: user?.email,
  });

  useEffect(() => {
    if (userId) {
      dispatch(fetchUser(userId));
    }
  }, [userId]);
  switch (status) {
    case APIStatus.IDLE:
      return <Spinner />;

    case APIStatus.PENDING:
      return <Spinner />;

    case APIStatus.FULFILLED:
      // eslint-disable-next-line react/jsx-no-useless-fragment
      return <>{children}</>;

    default:

      return (
        <ErrorPage
          title="You don&apos;t have access to this league exchange "
          text="Please contact a league exchange admin to add you as a member or verify that you have signed up using an email address which has received a league exchange invitation."
        />
      );
  }
}

export default UserLoaderContainer;
