import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AppDispatch } from '../../../redux/store';
import Options from '../../Options/Options';
import DeleteModal from '../../Modals/DeleteModal/DeleteModal';
import { Member } from '../../../types/member.type';
import {
  getCurrentLeagueSelector,
} from '../../../redux/features/leagueSlice';
import {
  deleteMemberFromTeam,
} from '../../../redux/features/membersSlice';
import EditMemberModal from '../../Modals/EditMember/EditMemberModal';
import styles from './MembersListItemOptions.module.scss';
import { EventName, telemetry } from '../../../telemetry/telemetry';

type Props = {
  member: Member;
};

function MembersListItemOptions({ member }: Props) {
  const dispatch = useDispatch<AppDispatch>();
  const currentLeague = useSelector(getCurrentLeagueSelector);

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);

  const deleteMember = async () => {
    if (currentLeague && member?.user) {
      const res = await dispatch(deleteMemberFromTeam({
        leagueId: currentLeague.public_identifier,
        teamId: member.team_id,
        userId: member.user.veo_id,
      }));

      if (res.meta.requestStatus === 'fulfilled') {
        await telemetry.track(EventName.LEX_USER_DELETED);
      }
    }
    setOpenDeleteModal(false);
  };

  const options = [
    { label: 'Edit member', onClick: () => setOpenEditModal(true), id: 0 },
    { label: 'Delete member', onClick: () => setOpenDeleteModal(true), id: 1 },
  ];

  const memberName = `${member.user!.first_name} ${member.user!.last_name}`;

  return (
    <>
      {!member.pending && <Options options={options} />}
      {openEditModal && (
        <EditMemberModal
          member={member}
          negativeActionClicked={() => setOpenEditModal(false)}
        />
      )}
      {openDeleteModal && (
        <DeleteModal
          title="Delete team member"
          negativeActionLabel="Go back"
          positiveActionLabel="Delete"
          negativeActionClicked={() => setOpenDeleteModal(false)}
          positiveActionClicked={deleteMember}
        >
          <p className={styles['modal-message']}>
            {`Are you sure you want to remove ${memberName} from ${member.team_name}?`}
          </p>
          <p className={styles['modal-data']}>
            If you delete a member from all teams in this league, they will lose
            all access to this league and will need to be reinvited.
          </p>
        </DeleteModal>
      )}
    </>
  );
}

export default MembersListItemOptions;
