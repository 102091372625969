import React from 'react';
import styles from './Spinner.module.scss';

type Props = {
  float?: boolean;
};

function Spinner({ float = true }: Props) {
  const classes = [styles.spinner];

  if (float) classes.push(styles.float);

  return <div className={classes.join(' ')} />;
}

export default Spinner;
