import React, { useState } from 'react';
import IconButton from '../../Button/IconButton';
import Icon from '../../Icon/Icon';
import styles from './LeagueOwnerListItem.module.scss';
import formStyles from './Form.module.scss';
import { LeagueOwner } from '../../../types/league.type';
import Chip from '../../Chip/Chip';

type LeagueOwnerListItemProps = {
  owner: LeagueOwner;
  onChange: (user: LeagueOwner) => void;
  onDelete: (user: LeagueOwner) => void;
  disabled?: boolean;
};

type FormValidity = {
  email?: ValidityState;
};

export const LeagueOwnerListItem = ({
  owner, onChange, onDelete, disabled,
}: LeagueOwnerListItemProps) => {
  const [validity, setValidity] = useState<FormValidity>({});

  const checkValidity = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, validity: newValidity } = event.target;
    setValidity({ ...validity, [name]: newValidity });
  };

  const onFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    checkValidity(event);
    onChange({
      ...owner,
      [name]: value,
    });
  };

  const emailError = (): string => {
    if (validity.email?.valueMissing) {
      return 'The email is required';
    }
    if (validity.email?.typeMismatch) {
      return 'Please enter a valid email address';
    }
    return '';
  };

  return (
    <div className={styles.row}>
      <div>
        <input
          type="email"
          name="email"
          placeholder="Email"
          required
          onChange={onFieldChange}
          onBlur={checkValidity}
          value={owner.email}
          disabled={disabled}
        />
        <div className={formStyles['error-feedback']}>{emailError()}</div>
      </div>
      <input type="text" name="role" value="League Owner" disabled />
      <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
        {owner.registered != null && (
          <Chip
            tagText={owner.registered ? 'ACCEPTED' : 'PENDING'}
            color={(owner.registered ? 'green' : undefined)}
          />
        )}
      </div>
      <div className={styles.action}>
        <IconButton onClick={() => onDelete(owner)}>
          <Icon name="trashBin" color="default" size="regular" />
        </IconButton>
      </div>
    </div>
  );
};
