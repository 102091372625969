/* eslint-disable no-alert */
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import * as privateApi from '../../apis/privateApi';
import Button from '../Button/Button';
import { ButtonType } from '../../enums/ButtonType';
import { addLeagueCrest } from '../../icons';
import styles from './CreateOrEditLeague.module.scss';
import formStyles from './Forms/Form.module.scss';
import { Team } from '../../types/team.type';
import { TeamMember } from '../../types/team_member.type';
import { League, LeagueOwner } from '../../types/league.type';
import LeagueForm from './Forms/LeagueForm';
import { Role } from '../../enums/Role';
import TeamForm from './Forms/TeamForm';
import MemberForm from './Forms/MemberForm';
import Spinner from '../Spinner/Spinner';
import DeleteModal from '../Modals/DeleteModal/DeleteModal';
import Card from '../Card/Card';
import Show from '../Utils/Show';
import { getUserSelector } from '../../redux/features/userSlice';

interface IServerResource<T> {
  server: T | null;
  local: T;
  dirty: boolean;
}

type TeamWithMembers = {
  team: IServerResource<Team>;
  members: IServerResource<TeamMember>[]
};

function resetServerResource<T>(serverResource: IServerResource<T>): IServerResource<T> {
  return {
    ...serverResource,
    local: serverResource.server || serverResource.local,
    dirty: false,
  };
}

function updateServerResource<T>(serverResource: IServerResource<T>, resource: T) {
  return {
    ...serverResource,
    local: resource,
    dirty: true,
  };
}

function initServerResource<T>(resource: T) {
  return {
    server: resource,
    local: resource,
    dirty: false,
  };
}

function newServerResource<T>(resource: T) {
  return {
    server: null,
    local: resource,
    dirty: false,
  };
}

function newLocalId() {
  return `_${Math.random()}`;
}

function isLocalId(id: string) {
  return id[0] === '_';
}

const BackOfficeEditLeague = () => {
  const navigate = useNavigate();

  const { leagueId } = useParams();
  const user = useSelector(getUserSelector);
  const [crest, setCrest] = useState<File | undefined>();
  const [league, setLeague] = useState<IServerResource<League> | null>(null);
  const [leagueOwners, setLeagueOwners] = useState<IServerResource<LeagueOwner>[] | undefined>();
  const [teams, setTeams] = useState<IServerResource<Team>[]>([]);
  const [members, setMembers] = useState<IServerResource<TeamMember>[]>([]);
  const [loading, setLoading] = useState(true);
  const [disabled, setDisabled] = useState(false);
  const [formIsValid, setFormIsValid] = useState(true);
  const [showDeleteLeagueModal, setShowDeleteModal] = useState(false);
  const isSuperUser = user.user?.is_super_user;

  useEffect(() => {
    const form = document.getElementById('league-edit-form');
    if (form instanceof HTMLFormElement) {
      setFormIsValid(form.checkValidity());
    }
  }, [league, teams, members]);

  const formIsDirty = (
    crest
    || league?.dirty
    || leagueOwners?.some((owner) => owner.dirty)
    || teams.some((team) => team.dirty)
    || members.some((member) => member.dirty)
  );

  const resetForm = () => {
    setCrest(undefined);
    if (league) setLeague(resetServerResource(league));
    setTeams(teams.map(resetServerResource));
    setMembers(members.map(resetServerResource));
  };

  const updateCrest = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.[0]) {
      setCrest(e.target.files[0]);
    } else {
      setCrest(undefined);
    }
  };

  const updateTeam = (id: string, newTeam: Team) => {
    setTeams(teams.map((team) => (
      (team.local.public_identifier === id)
        ? updateServerResource(team, newTeam)
        : team
    )));
  };

  const updateMember = (id: string, newMember: TeamMember) => {
    setMembers(members.map((member) => (
      (member.local.id === id && member.local.team_id === newMember.team_id)
        ? updateServerResource(member, newMember)
        : member
    )));
  };

  const updateLeagueOwner = (id: string, entity: LeagueOwner) => {
    setLeagueOwners(leagueOwners?.map((owner) => (
      (owner.local.id === id)
        ? updateServerResource(owner, entity)
        : owner
    )));
  };

  const addMember = (teamId: string) => {
    setMembers([...members, newServerResource({
      id: newLocalId(),
      first_name: '',
      last_name: '',
      email: '',
      permission: Role.ADMIN,
      team_id: teamId,
      registered: false,
    })]);
  };

  const addTeam = () => {
    setTeams([...teams, newServerResource({
      public_identifier: newLocalId(),
      league_id: leagueId!,
      team_name: '',
      thumbnail: '',
      abbreviation: '',
    })]);
  };

  const addLeagueOwner = () => {
    setLeagueOwners((prev) => [
      ...prev ?? [],
      newServerResource({
        id: '',
        email: '',
      }),
    ]);
  };

  const removeLeagueOwner = (owner: LeagueOwner) => {
    setLeagueOwners((prev) => prev?.filter((o) => o.local.id !== owner.id));
  };

  const deleteTeam = async (id: string) => {
    if (teams.length <= 2) { alert('You must have at least two teams.'); return; }
    if (!window.confirm('Are you sure?')) return;
    setDisabled(true);
    if (!isLocalId(id)) {
      await privateApi.deleteTeam(leagueId!, id)
        .catch(() => alert('An error occured. Some changes might not have been saved.'));
    }
    setTeams(teams.filter((team) => team.local.public_identifier !== id));
    setDisabled(false);
  };

  const deleteMember = async (member: TeamMember) => {
    if (!window.confirm('Are you sure?')) return;
    setDisabled(true);
    if (!isLocalId(member.id)) {
      if (member.registered) {
        await privateApi.deleteTeamMember(leagueId!, member.team_id, member.id)
          .catch(() => alert('An error occured. Some changes might not have been saved.'));
      } else {
        await privateApi.deleteInvitedMember(leagueId!, member.team_id, member.id)
          .catch(() => alert('An error occured. Some changes might not have been saved.'));
      }
    }
    setMembers(members.filter((c) => c.local.id !== member.id));
    setDisabled(false);
  };

  const navigateToBackOfficeDashboard = () => {
    const isAdmin = user.user?.is_backoffice_admin || user.user?.is_super_user;
    if (isAdmin) {
      navigate('/cockpit');
      return;
    }

    navigate('/');
  };

  // Load league data from server

  const getLeague = async () => {
    const response = await privateApi.getLeague(leagueId || '').then((res) => res.data);

    return [
      initServerResource({
        public_identifier: String(response.id),
        name: String(response.name),
        thumbnail: response.thumbnail ? (String(response.thumbnail)) : '',
      }),

      response.owners.map((owner: LeagueOwner) => initServerResource({
        id: String(owner.id),
        email: owner.email,
        registered: owner.registered,
      })),

      response.teams.map((team: any) => initServerResource({
        public_identifier: String(team.public_identifier),
        league_id: leagueId!,
        team_name: String(team.team_name),
        thumbnail: String(team.thumbnail),
        abbreviation: String(team.abbreviation),
      })),

      response.teams.flatMap((team: any) => (
        team.members.map((member: any) => initServerResource({
          id: String(member.id),
          first_name: member.first_name,
          last_name: member.last_name,
          email: String(member.email),
          permission: String(member.permission) as Role,
          team_id: team.public_identifier,
          registered: Boolean(member.registered),
        }))
      )),
    ];
  };

  const fetchData = async () => {
    const [leagueData, ownersData, teamsData, membersData] = await getLeague();
    setLeague(leagueData);
    setLeagueOwners(ownersData);
    setTeams(teamsData);
    setMembers(membersData);
    setLoading(false);
  };

  useEffect(
    () => {
      fetchData();
    },
    [leagueId],
  );

  // Save league data to server

  const saveCrest = async () => {
    if (!crest) return;
    const fileExtension = crest.name.slice(crest.name.lastIndexOf('.') + 1);
    const uploadUrl = await privateApi.getLeagueCrestUploadUrl(
      leagueId!,
      fileExtension,
    )
      .then((response) => response.data.league_crest_upload_url);
    await privateApi.uploadLeagueCrest(crest, uploadUrl);
    if (league?.local) {
      league.local.thumbnail = `${new URL(uploadUrl).origin}/${leagueId}/images/crest_original.${fileExtension}`;
      league.dirty = true;
    }
  };

  const createOrUpdateMember = async (member: IServerResource<TeamMember>, teamId: string) => {
    if (!member.server) {
      return privateApi.createLeagueMember(
        leagueId!,
        teamId,
        {
          email: member.local.email,
          permission: member.local.permission,
        },
      );
    } if (member.local.registered) {
      return privateApi.updateTeamMember(
        leagueId!,
        member.local.team_id,
        member.local.id,
        member.local.permission,
      );
    }
    return privateApi.updateInvitedMember(
      leagueId!,
      member.local.team_id,
      member.local.id,
      member.local.permission,
    );
  };

  const save = async () => {
    const teamsWithMembers: TeamWithMembers[] = [];
    teams.forEach((team) => {
      let teamWithMembers: TeamWithMembers | undefined;
      if (team.dirty) {
        teamWithMembers = {
          team,
          members: [],
        };
      }
      const teamMembers = members.filter((member) => (
        member.local.team_id === team.local.public_identifier && member.dirty));
      if (teamMembers.length > 0) {
        teamWithMembers = {
          team,
          members: teamMembers,
        };
      }
      if (teamWithMembers) teamsWithMembers.push(teamWithMembers);
    });

    setDisabled(true);
    await saveCrest().catch(() => {
      alert('The crest could not be saved.');
    });
    const updates = [];
    if (league?.dirty) {
      updates.push(privateApi.updateLeague(
        leagueId!,
        { ...league.local, name: league.local.name },
      ));
    }

    for (const leagueOwner of leagueOwners ?? []) {
      if (!leagueOwner.dirty) {
        continue;
      }

      if (leagueOwner.server == null) {
        updates.push(privateApi.createLeagueOwner(
          leagueId!,
          leagueOwner.local,
        ));
      } else if (leagueOwner.local == null) {
        updates.push(privateApi.deleteLeagueOwner(
          leagueId!,
          leagueOwner.server.id,
        ));
      }
    }

    updates.push(
      ...teamsWithMembers.map(async (teamWithMembers) => {
        if (teamWithMembers?.members.length) {
          if (teamWithMembers.team.server) {
            return teamWithMembers.members.map((member) => createOrUpdateMember(
              member,
              teamWithMembers.team.local.public_identifier,
            ));
          }
          return privateApi.createTeam(leagueId!, teamWithMembers.team.local)
            .then((response) => {
              const teamId = response.data.data.id;
              return Promise.all(
                teamWithMembers?.members!.map((member) => createOrUpdateMember(
                  member,
                  teamId,
                )),
              );
            });
        }
        if (teamWithMembers?.team.server) {
          return privateApi.updateTeam(
            leagueId!,
            teamWithMembers.team.local.public_identifier,
            teamWithMembers.team.local,
          );
        }
        return privateApi.createTeam(leagueId!, teamWithMembers.team.local);
      }),
    );

    await Promise.all(updates)
      .catch(() => alert('An error occured. Some changes might not have been saved.'))
      .finally(() => setDisabled(false));
    navigateToBackOfficeDashboard();
  };

  const deleteLeague = () => {
    setDisabled(true);
    privateApi.deleteLeague(leagueId!)
      .then(() => {
        navigateToBackOfficeDashboard();
      })
      .catch(() => {
        setDisabled(false);
        setShowDeleteModal(false);
        alert('Failed to delete the league. Please try again.');
      });
  };

  if (loading) return <Spinner />;

  const crestEl = () => {
    if (crest) {
      return <img src={URL.createObjectURL(crest)} alt="League Crest" />;
    } if (league?.local.thumbnail) {
      return <img src={league.local.thumbnail} alt="League Crest" />;
    }
    return addLeagueCrest;
  };

  const saveButtonDisabled = !formIsValid || !formIsDirty || disabled;

  return (
    <div>
      <Show iff={showDeleteLeagueModal}>
        <DeleteModal
          title="Delete League"
          negativeActionLabel="Go Back"
          positiveActionLabel="Delete League"
          negativeActionClicked={() => setShowDeleteModal(false)}
          positiveActionClicked={deleteLeague}
        >
          <p>
            Are you sure you want to delete&nbsp;
            <strong>{league?.server?.name}</strong>
            ?
          </p>
          <p>
            <strong>
              You&apos;ll revoke all league exchange access for the members and
              delete all recordings in the league exchange.
            </strong>
          </p>
          <p>
            <strong className="delete-warning">This can&apos;t be undone.</strong>
          </p>
        </DeleteModal>
      </Show>

      <form id="league-edit-form" className={styles.form}>
        <section className={styles.header}>

          {/* League crest */}
          <div className={styles['league-crest']}>
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label>
              {crestEl()}
              <input type="file" accept="image/*" onChange={updateCrest} />
            </label>
          </div>

          {/* Heading */}
          <div className={styles.heading}>
            <h2>Edit League</h2>
          </div>

          {/* Actions */}
          <div className={styles.actions}>
            <Button
              label="Delete"
              disabled={!isSuperUser}
              buttonType={isSuperUser ? ButtonType.DELETE_OUTLINED : ButtonType.DISABLED}
              onClick={() => setShowDeleteModal(true)}
            />
            <div className={styles.separator} />
            <Button
              label="Go back"
              buttonType={ButtonType.BACKOFFICE}
              onClick={navigateToBackOfficeDashboard}
            />
            <Button
              label="Reset"
              buttonType={ButtonType.CONFIRM}
              onClick={resetForm}
              disabled={!formIsDirty || disabled}
            />
            <Button
              label="Save Changes"
              buttonType={ButtonType.CONFIRM}
              onClick={save}
              disabled={saveButtonDisabled}
            />
          </div>
        </section>

        <Show iff={disabled}>
          <div className={styles.saving}>Saving...</div>
        </Show>

        {/* League form section */}
        <Card>
          <section className={styles.section}>
            <h2 className={styles['section-heading']}>League information</h2>
            <LeagueForm
              league={league!.local}
              leagueOwners={leagueOwners?.map(({ local }) => local) ?? []}
              onChange={(value) => setLeague(updateServerResource(league!, value as League))}
              onLeagueOwnerChange={(value) => updateLeagueOwner(value.id, value)}
              onLeagueOwnerAdd={addLeagueOwner}
              onLeagueOwnerRemove={removeLeagueOwner}
              disabled={disabled}
              refetchData={fetchData}
            />
          </section>
        </Card>

        {/* Team forms section */}
        {teams.map((team, teamIndex) => (
          <Card key={team.local.public_identifier}>

            <section className={styles.section}>
              <div className={styles['section-header']}>
                <h2 className={styles['section-heading']}>
                  Edit Team&nbsp;
                  {teamIndex + 1}
                </h2>
                <Button
                  label="Delete Team"
                  buttonType={ButtonType.BACKOFFICE}
                  onClick={() => deleteTeam(team.local.public_identifier)}
                  disabled={disabled}
                />
              </div>
              <TeamForm
                team={team.local}
                onChange={(value) => { updateTeam(team.local.public_identifier, value as Team); }}
                disabled={disabled}
              />
            </section>

            <section className={styles.section}>
              <h2 className={styles['section-heading']}>Edit Members</h2>

              <div className={formStyles.row}>
                <div className={formStyles.label}>Email</div>
                <div className={formStyles.label}>First name</div>
                <div className={formStyles.label}>Last name</div>
                <div className={formStyles.label}>Permission</div>
                <div className={formStyles.label}>Status</div>
                <div />
                {members
                  .filter((member) => member.local.team_id === team.local.public_identifier)
                  .map((member) => (
                    <MemberForm
                      key={member.local.id}
                      newUser={isLocalId(member.local.id)}
                      fromBackOfficeEdit
                      member={member.local}
                      onChange={(value) => { updateMember(member.local.id, value as TeamMember); }}
                      onDelete={(value) => deleteMember(value as TeamMember)}
                      disabled={disabled}
                    />
                  ))}
              </div>
              <section className={styles['add-member']}>
                <Button
                  label="Add Member"
                  buttonType={ButtonType.BACKOFFICE}
                  onClick={() => addMember(team.local.public_identifier)}
                  disabled={disabled}
                />
              </section>
            </section>
          </Card>
        ))}

        <section className={styles['add-team']}>
          <Button
            label="Add Team"
            buttonType={ButtonType.BACKOFFICE}
            onClick={addTeam}
          />
        </section>
      </form>
    </div>
  );
};

export default BackOfficeEditLeague;
