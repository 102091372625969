import React from 'react';
import * as icons from '../../icons';
import { joinStyles } from '../../utils/utils';
import styles from './Icon.module.scss';

type Props = {
  name: keyof (typeof icons);
  color?: 'default';
  size?: 'small' | 'regular' | 'large';
  className?: string;
};

const Icon = ({
  name,
  color,
  size,
  className,
}: Props) => {
  const classNames = ['icon'];
  if (className) classNames.push(className);
  if (color) classNames.push(`color-${color}`);
  if (size) classNames.push(`size-${size}`);

  return (
    <div className={joinStyles(styles, classNames)}>
      {icons[name]}
    </div>
  );
};

export default Icon;
