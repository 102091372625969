import React from 'react';

import styles from './Button.module.scss';
import { ButtonType } from '../../enums/ButtonType';

type Props = {
  label: string;
  buttonType: ButtonType;
  onClick?: () => any;
  submit?: boolean;
  disabled?: boolean;
};

function Button({
  label, buttonType, onClick, submit, disabled,
}: Props) {
  const classes = [styles.button];
  switch (buttonType) {
    case ButtonType.CONFIRM:
      classes.push(styles['button-confirm']);
      break;
    case ButtonType.CANCELLED:
      classes.push(styles['button-cancel']);
      break;
    case ButtonType.DISABLED:
      classes.push(styles['button-disabled']);
      break;
    case ButtonType.DELETE:
      classes.push(styles['button-delete']);
      break;
    case ButtonType.DELETE_OUTLINED:
      classes.push(styles['button-delete-outlined']);
      break;
    case ButtonType.BACKOFFICE:
      classes.push(styles['button-backoffice']);
      break;
    case ButtonType.DARK:
      classes.push(styles['button-dark']);
      break;
    default:
  }

  return (
    <div>
      <button
        type={submit ? 'submit' : 'button'}
        onClick={onClick}
        className={classes.join(' ')}
        disabled={disabled}
      >
        {label}
      </button>
    </div>
  );
}

export default Button;
