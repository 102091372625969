import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../redux/store';
import { ButtonType } from '../../../enums/ButtonType';
import { getCurrentLeagueSelector, getLeaguesSelector } from '../../../redux/features/leagueSlice';
import { getCurrentLeagueRecordingsSelector } from '../../../redux/features/recordingsSlice';
import { fetchTeams, getTeamsSelector } from '../../../redux/features/teamsSlice';
import Button from '../../Button/Button';
import Dropdown from '../../Dropdown/Dropdown';
import styles from './EditRecordingModal.module.scss';
import { EditMatch } from '../../../apis/privateApi';
import ModalWrapper from '../ModalWrapper';
import { Role } from '../../../enums/Role';

type Props = {
  negativeActionClicked: () => any;
  positiveActionClicked: ((leagueId: string,
    matchId: string,
    updatedValues: EditMatch) => any);
  recordingId?: string,
  recordingName?: string,
};

function EditRecordingModal({
  negativeActionClicked,
  positiveActionClicked,
  recordingId,
  recordingName,
}: Props) {
  const dispatch = useDispatch<AppDispatch>();

  const currentLeague = useSelector(getCurrentLeagueSelector);
  const teams = useSelector(getTeamsSelector);
  const leagues = useSelector(getLeaguesSelector);
  const recordings = useSelector(getCurrentLeagueRecordingsSelector);
  const recording = recordings.find((rec) => rec.public_identifier === recordingId);
  const currentDate = new Date().toISOString().slice(0, 10);

  const [league, setLeague] = useState(useSelector(getCurrentLeagueSelector));
  const [homeTeamId, setHomeTeamId] = useState(recording?.home_team_id);
  const [awayTeamId, setAwayTeamId] = useState(recording?.away_team_id);
  const [homeTeamScore, setHomeTeamScore] = useState(recording?.team_home_score);
  const [awayTeamScore, setAwayTeamScore] = useState(recording?.team_away_score);
  const [recordedDate, setRecordedDate] = useState(recording?.recorded_date.slice(0, 10));

  const leagueOptions = leagues?.flatMap((leagueOption) => {
    // TODO: move this logic to permissions.ts
    if (![Role.ADMIN, Role.EDITOR].includes(leagueOption.permission)) {
      return [];
    }

    return [{
      id: leagueOption.public_identifier,
      label: leagueOption.name,
    }];
  });

  const teamOptions = teams?.map((team) => ({
    label: team.team_name,
    id: team.public_identifier,
  }));

  useEffect(() => {
    if (currentLeague?.public_identifier) {
      dispatch(fetchTeams(currentLeague.public_identifier));
    }
  }, [currentLeague]);

  // fetching teams for the new league and resetting chosen teams
  const fetchLeagueTeams = (leagueId: string) => {
    dispatch(fetchTeams(leagueId));
    setHomeTeamId('');
    setAwayTeamId('');
  };

  const onSubmit = async (e: { preventDefault: () => void; }, matchId: string) => {
    e.preventDefault();

    if (homeTeamId === '') {
      // eslint-disable-next-line no-alert
      alert('Please select a home team');
      return;
    }

    if (awayTeamId === '') {
      // eslint-disable-next-line no-alert
      alert('Please select an away team');
      return;
    }

    const data: EditMatch = {
      team_away_score: awayTeamScore,
      team_home_score: homeTeamScore,
      team_home_id: homeTeamId,
      team_away_id: awayTeamId,
      league_id: league!.public_identifier,
      recorded_date: recordedDate,
    };

    positiveActionClicked(league!.public_identifier, matchId, data);
  };

  // TODO: we are making sure each league has atleast two teams, so we can remove these checks
  const defaultPlaceholder = teams.length ? 'Select a team' : 'No teams in league';
  const homeTeamPlaceholder = homeTeamId ? recording?.home_team_name : defaultPlaceholder;
  const awayTeamPlaceholder = awayTeamId ? recording?.away_team_name : defaultPlaceholder;

  // dont allow same team to be selected for both home and away
  const onTeamOptionSelect = (side: string, teamOptionId: string) => {
    if (side === 'home') {
      if (teamOptionId === awayTeamId) setAwayTeamId('');
      setHomeTeamId(teamOptionId);
    }

    if (side === 'away') {
      if (teamOptionId === homeTeamId) setHomeTeamId('');
      setAwayTeamId(teamOptionId);
    }
  };

  return (
    <ModalWrapper>
      <div className={styles.edit}>
        <h2 className={styles['edit-title']}>
          Edit Details
        </h2>
        <form action="#" method="post" onSubmit={(e) => onSubmit(e, recording.public_identifier)} className={styles['edit-form']}>
          <div>
            <p className={styles['edit-form-titles']}>Video selected</p>
            <input className={styles['edit-form-input']} disabled placeholder={recordingName} />
          </div>
          <div>
            <p className={styles['edit-form-titles']}>Edit League</p>
            <div>
              <Dropdown
                placeholder={league?.name!}
                onOptionClicked={(leagueId: string) => {
                  const foundLeague = leagues.find((
                    newLeague,
                  ) => newLeague.public_identifier === leagueId);
                  if (foundLeague) {
                    setLeague(foundLeague);
                    fetchLeagueTeams(foundLeague.public_identifier);
                  }
                }}
                options={leagueOptions}
              />
            </div>
          </div>
          <div className={styles['edit-form-home-away-wrapper']}>
            <div className={styles['edit-form-width']}>
              <p className={styles['edit-form-titles']}>Edit Home Team</p>
              <div className={styles['edit-form-dropdown']}>
                <Dropdown
                  clearSelectedOption={homeTeamId === ''}
                  placeholder={homeTeamPlaceholder}
                  onOptionClicked={(teamOptionId: string) => onTeamOptionSelect('home', teamOptionId)}
                  options={teamOptions}
                />
              </div>
            </div>
            <div className={styles['edit-form-width']}>
              <p className={styles['edit-form-titles']}>Edit Away Team</p>
              <div className={styles['edit-form-dropdown']}>
                <Dropdown
                  clearSelectedOption={awayTeamId === ''}
                  placeholder={awayTeamPlaceholder}
                  onOptionClicked={(teamOptionId: string) => onTeamOptionSelect('away', teamOptionId)}
                  options={teamOptions}
                />
              </div>
            </div>
          </div>
          <div>
            <p className={styles['edit-form-titles']}>Edit Score</p>
            <div className={styles['edit-score']}>
              <span className={styles['edit-score-teams']}>
                HOME
                {' '}
              </span>
              <div className={styles['edit-score-content']}>
                <span>
                  <input
                    type="text"
                    placeholder={homeTeamScore}
                    min={0}
                    value={Number.isNaN(homeTeamScore) ? 0 : homeTeamScore}
                    onChange={(e) => setHomeTeamScore(Math.max(+e.target.value, 0))}
                    className={styles['edit-score-content-input']}
                  />
                </span>
                <span>-</span>
                <span>
                  <input
                    type="text"
                    placeholder={awayTeamScore}
                    min={0}
                    value={Number.isNaN(awayTeamScore) ? 0 : awayTeamScore}
                    onChange={(e) => setAwayTeamScore(Math.max(+e.target.value, 0))}
                    className={styles['edit-score-content-input']}
                  />
                </span>
              </div>
              <span className={styles['edit-score-teams']}>
                {' '}
                AWAY
              </span>
            </div>
          </div>
          <div>
            <p className={styles['edit-form-titles']}>Edit Match Date</p>
            <div className={styles['edit-date-container']}>
              <input
                type="date"
                onChange={(e) => setRecordedDate(e.target.value)}
                className={styles['edit-date-input']}
                defaultValue={recordedDate}
                max={currentDate}
              />
            </div>
          </div>
          <footer className={styles.footer}>
            <Button
              buttonType={ButtonType.CANCELLED}
              label="Cancel"
              onClick={negativeActionClicked}
            />
            <Button
              submit
              buttonType={ButtonType.CONFIRM}
              label="Save"
            />
          </footer>
        </form>
      </div>
    </ModalWrapper>
  );
}

export default EditRecordingModal;
