import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { telemetry } from '../telemetry/telemetry';

const LEX_PAGE_VIEW_CATEGORY = 'League Exchange';

export const usePageViewEffect = () => {
  const location = useLocation();

  useEffect(() => {
    telemetry.page(LEX_PAGE_VIEW_CATEGORY);
  }, [location]);
};
