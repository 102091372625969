import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import styles from './LeagueNavigationContainer.module.scss';
import { Role } from '../../enums/Role';
import { getCurrentLeagueSelector } from '../../redux/features/leagueSlice';

function LeagueNavigationContainer() {
  const currentLeague = useSelector(getCurrentLeagueSelector);

  // User permissions
  // TODO: move this to permissions.ts
  const permission = currentLeague?.permission;
  const canViewMembers = permission === Role.ADMIN || permission === Role.EDITOR;

  return (
    <div className={styles.links}>
      <NavLink
        className={({ isActive }) => (isActive ? styles.active : styles.navigate)}
        to="/"
      >
        Recordings
      </NavLink>

      {canViewMembers
        && (
          <NavLink
            className={({ isActive }) => (isActive ? styles.active : styles.navigate)}
            to="/members"
          >
            Members
          </NavLink>
        )}
    </div>
  );
}

export default LeagueNavigationContainer;
