import React from 'react';
import styles from './RolesTooltip.module.scss';

interface Option {
  id: number;
  title: string;
  paragraph: string;
}

type Props = {
  roles: Array<Option>;
};

const RolesTooltip = ({ roles }: Props) => (
  <div className={styles['roles-tooltip']}>
    {roles.map((role) => (
      <div className={styles['roles-tooltip-content']} key={role.title}>
        <p className={styles['roles-tooltip-content-title']}>{role.title}</p>
        <p className={styles['roles-tooltip-content-paragraph']}>{role.paragraph}</p>
      </div>
    ))}
  </div>
);

export default RolesTooltip;
