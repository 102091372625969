/* eslint-disable jsx-a11y/media-has-caption */
import React, { useEffect } from 'react';

import { Button } from '@veo/web-design-system';
import styles from './VideoPlayerModal.module.scss';
import { veoLogo, optionsHorizontal, closeButton } from '../../../icons';
import RecordingsListItemOptions from '../../Lists/RecordingsList/RecordingsListItemOptions';

import { lexPlayerStatus } from '../../../utils/zola_agent';
import { EventName, telemetry } from '../../../telemetry/telemetry';
import { LEX_UPLOADS_CDN_BASE_URL, VEO_CDN_BASE_URL } from '../../../settings';

const VEO_CDN_PATTERN = /(?<=\.)(veocdn)(?=\.)/;
const LEX_UPLOADS_BUCKET_PATTERN = /lex-uploads-(staging|production)/;

/* We have 2 types of videos:
  - shared from clubhouse to LX and
  - uploaded directly to LX
   For the first type, we need to get the download URL with Content-Disposition header
   from the VEO CDN. For the second type, we need to get the download URL from the
   Cloudfront distribution pointing to LEX Uploads bucket that returns Content-Disposition header.
 */
const getDownloadUrl = (recordingUrl: string) => {
  const url = new URL(recordingUrl);

  if (VEO_CDN_PATTERN.test(url.hostname)) {
    return `${VEO_CDN_BASE_URL}${url.pathname}`;
  }

  if (LEX_UPLOADS_BUCKET_PATTERN.test(url.hostname)) {
    return `${LEX_UPLOADS_CDN_BASE_URL}${url.pathname}`;
  }

  // All videos should match one of the patterns above. If not, return the original URL
  return url.href;
};

type Props = {
  recordingTitle: string;
  date: any;
  teamHomeAbbreviation: string;
  teamAwayAbbreviation: string;
  teamHomeScore: number;
  teamAwayScore: number;
  onCloseModal: () => void;
  recordingUrl: string;
  recordingId: string;
};

function VideoPlayerModal({
  recordingTitle,
  date,
  teamHomeAbbreviation,
  teamAwayAbbreviation,
  teamHomeScore,
  teamAwayScore,
  onCloseModal,
  recordingUrl,
  recordingId,
}: Props) {
  useEffect(() => {
    // Notify Zola that player is on
    lexPlayerStatus(true);

    return function onPlayerClose() {
      // Notify Zola that player is off
      lexPlayerStatus(false);
    };
  });

  const handleDownloadClick = async () => {
    await telemetry.track(EventName.LEX_RECORDING_DOWNLOADED);

    const downloadUrl = getDownloadUrl(recordingUrl);
    window.location.replace(downloadUrl);
  };

  return (
    <div className={styles['video-modal-wrapper']}>
      <div className={styles['video-nav-wrapper']}>
        <div className={styles['logo-data-wrapper']}>
          <button className={styles['logo-button']} type="button" onClick={onCloseModal}>
            {veoLogo}
          </button>
          <div className={styles['title-date']}>
            <p className={styles['video-title-container']}>
              {recordingTitle}
            </p>
            <div className={styles['video-date-container']}>
              <span>{date}</span>
            </div>
          </div>
        </div>
        <div className={styles['team-score']}>
          <p className={styles['team-title']}>{teamHomeAbbreviation}</p>
          <p className={styles['score-data']}>{teamHomeScore}</p>
          <p className={styles['score-data']}>:</p>
          <p className={styles['score-data']}>{teamAwayScore}</p>
          <p className={styles['team-title']}>{teamAwayAbbreviation}</p>
        </div>
        <div className={styles['icon-wrapper']}>
          <Button variant="text" iconStart="download" size="md" label="Download" onClick={handleDownloadClick} />
          <RecordingsListItemOptions
            icon={optionsHorizontal}
            recordingId={recordingId}
            recordingTitle={recordingTitle}
          />
          <button className={styles['icon-button']} type="button" onClick={onCloseModal}>{closeButton}</button>

        </div>
      </div>
      <div className={styles['video-wrapper']}>
        <video className={styles.video} id="matchVideo" autoPlay controls src={recordingUrl} />
      </div>

    </div>
  );
}

export default VideoPlayerModal;
