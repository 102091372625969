import React from 'react';
import styles from './DropdownLeague.module.scss';
import { League } from '../../types/league.type';
import { chevronDown, chevronUp } from '../../icons';
import CloseComponentOutside from '../Wrapper/CloseComponentOutside';
import Icon from '../Icon/Icon';

type Props = {
  value: League;
  leagues: League[];
  disabled?: boolean;
  onChange?: (league: League) => void;
};

function DropdownLeague({
  value,
  leagues,
  disabled,
  onChange,
}: Props) {
  const { ref, isComponentVisible, setIsComponentVisible } = CloseComponentOutside(false);

  const toggleShowOptions = () => setIsComponentVisible(!isComponentVisible);

  const onClickOption = (league: League) => () => {
    setIsComponentVisible(false);
    if (onChange) { onChange(league); }
  };

  const options = (
    <ul className={styles['list-container']}>
      {leagues.map((option) => (
        <button
          type="button"
          className={styles.list}
          onClick={onClickOption(option)}
          key={option.public_identifier}
        >
          <div className={styles['crest-img']}>
            {(option.thumbnail)
              ? <img src={option.thumbnail} alt="Crest" />
              : <Icon name="placeholderCrest" size="large" />}
          </div>
          {option.name}
        </button>
      ))}
    </ul>
  );

  return (
    <div className={styles.container} ref={ref}>
      <button
        type="button"
        className={styles.select}
        onClick={toggleShowOptions}
        disabled={disabled}
      >
        <div className={styles['crest-img']}>
          {(value.thumbnail)
            ? <img src={value.thumbnail} alt="Crest" />
            : <Icon name="placeholderCrest" size="large" />}
        </div>
        {value.name}
        <div className={styles['chevron-icon']}>
          {isComponentVisible && options ? chevronUp : chevronDown}
        </div>
      </button>
      {isComponentVisible
        && options}
    </div>

  );
}

export default DropdownLeague;
