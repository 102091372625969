import React, { useEffect } from 'react';

import styles from './ModalWrapper.module.scss';

import { isLexModalOpen } from '../../utils/zola_agent';

type Props = {
  children: JSX.Element;
  isResponsiveModal?: boolean;
};

function ModalWrapper({
  children, isResponsiveModal,
}: Props) {
  useEffect(() => {
    // Notify Zola that a modal in lex is opened
    isLexModalOpen(true);

    return function onPlayerClose() {
      // Notify Zola that the modal is closed
      isLexModalOpen(false);
    };
  });

  return (
    <div>
      <div className={styles['modal-wrapper']}>
        <div className={isResponsiveModal ? styles['modal-wrapper-responsive'] : styles['modal-wrapper-cover-the-page']}>
          <div>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalWrapper;
