import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AppDispatch } from '../../../redux/store';
import Button from '../../Button/Button';
import { ButtonType } from '../../../enums/ButtonType';
import { Role, formatRole } from '../../../enums/Role';
import Dropdown from '../../Dropdown/Dropdown';
import styles from './EditMemberModal.module.scss';
import ModalWrapper from '../ModalWrapper';
import { Member } from '../../../types/member.type';
import { editTeamMember } from '../../../redux/features/membersSlice';
import {
  getCurrentLeagueSelector,
} from '../../../redux/features/leagueSlice';
import { infoIcon } from '../../../icons';
import RolesTooltip from '../../Tooltip/RolesTooltip/RolesTooltip';

type Props = {
  member: Member,
  negativeActionClicked: () => any;
};

function EditMemberModal({
  member,
  negativeActionClicked,
}: Props) {
  const initialPermission = formatRole(String(member.permission));
  const permissionOptions = Object.keys(Role)
    .map((role) => ({
      label: formatRole(role),
      id: role,
    }));

  const dispatch = useDispatch<AppDispatch>();
  const [permission, setPermission] = useState(initialPermission);
  const currentLeague = useSelector(getCurrentLeagueSelector);

  const editMember = () => {
    if (currentLeague && member?.user) {
      dispatch(editTeamMember({
        leagueId: currentLeague.public_identifier,
        teamId: member.team_id,
        userId: member.user.veo_id,
        permission,
      }));
    }
  };

  return (
    <ModalWrapper>
      <div className={styles.member}>
        <h2 className={styles['member-invite']}>
          Edit member
        </h2>
        <form className={styles['member-form']}>
          <div>
            <p className={styles['member-form-titles']}>First name</p>
            <input className={styles['member-form-input']} type="text" name="name" id="name" value={member.user?.first_name} disabled />
          </div>
          <div>
            <p className={styles['member-form-titles']}>Last name</p>
            <input className={styles['member-form-input']} type="text" name="surname" id="surname" value={member.user?.last_name} disabled />
          </div>
          <div>
            <div className={styles['member-form-icon-wrapper']}>
              <p className={styles['member-form-titles']}>Permission</p>
              <div className={styles['member-form-icon']}>
                {infoIcon}
                <div className={styles['member-form-icon-tooltip']}><RolesTooltip roles={[{ title: 'Admin', paragraph: 'Can add users to this league exchange besides adding and viewing the recordings.', id: 1 }, { title: 'Editor', paragraph: 'Can add recordings to the league exchange besides viewing recordings.', id: 1 }, { title: 'Contributor', paragraph: 'Can view the recordings in the league exchange.', id: 1 }]} /></div>
              </div>
            </div>
            <Dropdown
              placeholder={initialPermission}
              options={permissionOptions}
              onOptionClicked={setPermission}
            />
          </div>
          <footer className={styles.footer}>
            <Button
              buttonType={ButtonType.CANCELLED}
              label="Cancel"
              onClick={negativeActionClicked}
            />
            <Button
              buttonType={ButtonType.CONFIRM}
              label="Save"
              onClick={editMember}
              disabled={initialPermission === formatRole(permission)}
            />
          </footer>
        </form>
      </div>
    </ModalWrapper>
  );
}

export default EditMemberModal;
