import { MemberAddData } from '../../../../types/member.type';
import axios from '../../../privateApi';

export const getLeagueMembers = (leagueId: string) => (
  axios.get(`leagues/${leagueId}/members?expand=user`)
);

export const createLeagueMember = (leagueId: string, teamId: string, data: any) => (
  axios.post(`leagues/${leagueId}/members`, { ...data, team_id: teamId })
);

export const inviteLeagueMember = (data: MemberAddData, leagueId: string) => (
  axios.post(`leagues/${leagueId}/members`, { ...data })
);
