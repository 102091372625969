import React from 'react';
import { useNavigate } from 'react-router-dom';

type ErrorFallbackProps = {
  error: {
    message: string
  },
  resetErrorBoundary: () => void
};

// TODO: Wait for design and style properly
const ErrorFallback = ({ error, resetErrorBoundary }: ErrorFallbackProps) => {
  const navigate = useNavigate();

  return (
    <div role="alert">
      <p>Something went wrong 👀</p>
      {/* Think about what messages we want to show */}
      <pre>{error.message}</pre>
      <button
        type="button"
        onClick={() => {
          resetErrorBoundary();
          navigate('/');
        }}
      >
        Ok
      </button>
    </div>
  );
};

export default ErrorFallback;
