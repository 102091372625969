import React from 'react';
import styles from './OptionsMenu.module.scss';

interface Option {
  id: number;
  label: string;
  onClick: () => void;
}

type Props = {
  options: Array<Option>;
  classes: string[];
};

function OptionsMenu({ options, classes }: Props) {
  const cls = ['options-menu-container', ...classes].map((c: string) => styles[c]).join(' ');
  return (
    <div>
      <div className={cls}>
        {options.map((option) => (
          <button type="button" onClick={option.onClick} className={styles['options-menu-button']} key={option.id}>
            {option.label}
          </button>
        ))}
      </div>
    </div>
  );
}

export default OptionsMenu;
