type Props = {
  iff: boolean;
  fallback?: JSX.Element;
  children: JSX.Element;
};

const Show = ({ iff, fallback, children }: Props) => {
  if (!iff) return fallback || null;
  return children;
};

export default Show;
