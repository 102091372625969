import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../Button/Button';
import { ButtonType } from '../../../enums/ButtonType';
import Dropdown from '../../Dropdown/Dropdown';
import styles from './InviteMembersModal.module.scss';
import ModalWrapper from '../ModalWrapper';
import { getUserSelector } from '../../../redux/features/userSlice';
import { getCurrentLeagueSelector } from '../../../redux/features/leagueSlice';
import { AppDispatch } from '../../../redux/store';
import { fetchLeagueMembers, inviteMemberToLeague } from '../../../redux/features/membersSlice';
import { fetchUserTeams, getUserTeamsSelector } from '../../../redux/features/teamsSlice';
import { Role, formatRole } from '../../../enums/Role';
import { infoIcon } from '../../../icons';
import RolesTooltip from '../../Tooltip/RolesTooltip/RolesTooltip';
import { APIStatus } from '../../../enums/APIStatus';
import Spinner from '../../Spinner/Spinner';
import { MemberAddType } from '../../../types/member.type';
import { EventName, telemetry } from '../../../telemetry/telemetry';

type Props = {
  negativeActionClicked: () => any;
};

function InviteMembersModal({
  negativeActionClicked,
}: Props) {
  const { user } = useSelector(getUserSelector);
  const league = useSelector(getCurrentLeagueSelector);
  const userTeams = useSelector(getUserTeamsSelector);
  const dispatch = useDispatch<AppDispatch>();
  const [permission, setPermission] = useState('');
  const [membersInfo, setMembersInfo] = useState({
    email: '', id: 0,
  });
  const [chosenTeam, setChosenTeam] = useState('');

  const teamOptions = userTeams.teams
    .filter((team) => team.permission === 'ADMIN')
    .map((team) => ({
      label: team.team_name,
      id: team.public_identifier,
    }));

  const shouldChooseTeam = teamOptions.length > 1;

  useEffect(() => {
    dispatch(fetchUserTeams({ leagueId: league!.public_identifier, userId: user!.veo_id }));
  }, []);

  const onInviteMember = async (e: { preventDefault: () => void; }) => {
    e.preventDefault();
    const payload: MemberAddType = {
      data: {
        email: membersInfo.email,
        permission,
      },
      leagueId: league!.public_identifier,
    };
    if (shouldChooseTeam) {
      payload.data.team_id = chosenTeam;
    } else {
      payload.data.member_id = user!.veo_id;
    }
    const res = await dispatch(inviteMemberToLeague(payload));
    if (res.meta.requestStatus === 'fulfilled') {
      await telemetry.track(EventName.LEX_USER_INVITED);
    }

    await dispatch(fetchLeagueMembers(league!.public_identifier));
    negativeActionClicked();
  };

  const permissions = [Role.ADMIN, Role.EDITOR, Role.VIEWER]
    .map((role) => ({
      label: formatRole(role),
      id: role,
    }));

  const handleMemberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setMembersInfo({ ...membersInfo, [name]: value });
  };

  let modal;
  if (userTeams.status === APIStatus.FULFILLED) {
    modal = (
      <ModalWrapper>
        <div className={styles.invite}>
          <h2 className={styles['invite-member']}>
            Invite member
          </h2>
          <form action="#" method="post" onSubmit={onInviteMember} className={styles['invite-form']}>
            <div>
              <p className={styles['invite-form-titles']}>Email address</p>
              <div className={styles['invite-form-dropdown']}>
                <input className={styles['invite-form-input']} type="email" name="email" id="email" placeholder="Email address" required onChange={(e) => handleMemberChange(e)} />
              </div>
            </div>
            <div>
              {shouldChooseTeam
                && (
                  <>
                    <p className={styles['invite-form-titles']}>Team</p>
                    <div className={styles['invite-form-dropdown']}>
                      <Dropdown
                        placeholder="Select team"
                        options={teamOptions}
                        onOptionClicked={(teamId) => setChosenTeam(teamId)}
                      />
                    </div>
                  </>
                )}
            </div>
            <div>
              <div className={styles['invite-form-icon-wrapper']}>
                <p className={styles['invite-form-titles']}>
                  League permission
                </p>
                <div className={styles['invite-form-icon']}>
                  {infoIcon}
                  <div className={styles['invite-form-icon-tooltip']}><RolesTooltip roles={[{ title: 'Admin', paragraph: 'Can add users to this league exchange besides adding and viewing the recordings.', id: 1 }, { title: 'Editor', paragraph: 'Can add recordings to the league exchange besides viewing recordings.', id: 1 }, { title: 'Contributor', paragraph: 'Can view the recordings in the league exchange.', id: 1 }]} /></div>
                </div>
              </div>
              <Dropdown placeholder="Select role" options={permissions} onOptionClicked={(permissionVal) => setPermission(permissionVal)} />
            </div>
            <footer className={styles.footer}>
              <Button
                buttonType={ButtonType.CANCELLED}
                label="Cancel"
                onClick={negativeActionClicked}
              />
              <Button
                submit
                buttonType={ButtonType.CONFIRM}
                label="Send Invite"
                disabled={!permission
                  || !membersInfo.email}
              />
            </footer>
          </form>
        </div>
      </ModalWrapper>
    );
  } else {
    modal = <Spinner />;
  }
  return (
    modal
  );
}

export default InviteMembersModal;
