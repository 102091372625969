import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { ButtonType } from '../../../enums/ButtonType';
import Button from '../../Button/Button';
import styles from './SaveRecordingModal.module.scss';
import ModalWrapper from '../ModalWrapper';
import Dropdown from '../../Dropdown/Dropdown';
import { infoIcon } from '../../../icons';
import { getUserSelector } from '../../../redux/features/userSlice';
import { getUserClubhouses } from '../../../apis/privateApi';
import Spinner from '../../Spinner/Spinner';
import { MatchImportData } from '../../../types/match_import.type';

type Props = {
  veoId: string;
  recordingTitle: string;
  recordingThumbnail?: string;
  recordingDuration?: string;
  buttonType?: ButtonType;
  positiveActionClicked: (data: MatchImportData) => Promise<void>;
  negativeActionClicked: () => any;
};

type ClubhouseResponseType = {
  admin: string;
  public_identifier: string;
  name: string;
  common_name: string;
  short_name: string;
  slug: string;
};

type ClubhouseOptionType = {
  label: string,
  id: string;
  public_uuid: string;
};

type SelectedRecordingType = {
  uploadUuid: string;
  clubSlug: string;
  userUuid: string;
};

const SaveRecordingModal = ({
  veoId,
  buttonType = ButtonType.CONFIRM,
  recordingTitle,
  recordingThumbnail,
  recordingDuration,
  positiveActionClicked,
  negativeActionClicked,
}: Props) => {
  const [clubhouses, setClubhouses] = useState([] as ClubhouseOptionType[]);
  const [loading, setLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedRecording, setSelectedRecording] = useState({} as SelectedRecordingType);
  const [userId, setUserId] = useState('');
  const { user } = useSelector(getUserSelector);

  const clubhousesOptions = [...clubhouses];
  const modalTitle = clubhouses.length ? 'Add to my recordings' : 'Create Clubhouse';
  const closeButtonLabel = clubhouses.length ? 'Cancel' : 'Close';
  const positiveButtonLabel = clubhouses.length ? 'Add to my recordings' : 'Create Clubhouse';
  const clubhouseCreationUrl = `${process.env.REACT_APP_ZOLA_APP_URL}/clubhouses/create/`;
  const clubhouseCreationText = 'create a new one.';
  const modalDescription = 'Choose an existing Clubhouse to add the recording to or ';
  const dropdownPlaceholder = 'Select a Clubhouse';
  const modalNoClubhouseDescription = 'Seems like you don\'t have a Clubhouse, please create one to add the video to your recordings.';
  const videoSelected = 'Video Selected';
  const chooseClubhouse = 'Choose Clubhouse';

  const retrieveClubhouses = async (userUuid: string) => {
    const response = await getUserClubhouses(userUuid);

    if (!response?.data?.data) {
      setLoading(false);
      return;
    }

    const userClubhouses = response.data.data;
    setClubhouses(
      userClubhouses.map((clubhouse: ClubhouseResponseType) => ({
        label: clubhouse.common_name,
        id: clubhouse.slug,
        public_uuid: clubhouse.public_identifier,
      })),
    );
    setLoading(false);
  };

  useEffect(() => {
    if (!user) {
      return;
    }

    const userUuid = String(user.veo_id);
    setUserId(userUuid);
    retrieveClubhouses(userUuid);
  }, []);

  const onSubmit = async (
    e: { preventDefault: () => void; },
    selectedRecordingData: SelectedRecordingType,
  ) => {
    e.preventDefault();
    setIsSubmitting(true);
    const { uploadUuid, clubSlug, userUuid } = selectedRecordingData;

    const data = {
      upload_veo_id: uploadUuid,
      club_slug: clubSlug,
      user_veo_id: userUuid,
    };

    await positiveActionClicked(data);
    setIsSubmitting(false);
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <ModalWrapper>
      <div className={styles['modal-content']}>
        <h2 className={styles['modal-content-header']}>
          {modalTitle}
        </h2>
        <form action="#" method="post" onSubmit={(e) => onSubmit(e, selectedRecording)} className={styles['modal-content-form']}>
          {Boolean(!loading && !clubhouses.length) && (
          <div className={styles['modal-content-warning']}>
            <div className={styles['modal-content-warning-icon']}>
              {infoIcon}
            </div>
            <div className={styles['modal-content-warning-text']}>
              {modalNoClubhouseDescription}
            </div>
          </div>
          )}
          {Boolean(!loading && clubhouses.length) && (
          <div className={styles['modal-content-description']}>
            {modalDescription}
            <a target="self" href={clubhouseCreationUrl}>{clubhouseCreationText}</a>
          </div>
          )}
          <div className={styles['modal-content-titles']}>
            {videoSelected}
          </div>
          <div className={styles['modal-content-video']}>
            {recordingThumbnail && (
            <div className={styles['modal-content-video-thumbnail']}>
              <img src={recordingThumbnail} alt="Recording Thumbnail" />
            </div>
            )}
            <div className={styles['modal-content-video-match']}>
              <div className={styles['modal-content-video-match-title']}>
                {recordingTitle}
              </div>
              <div className={styles['modal-content-video-match-duration']}>
                {recordingDuration}
              </div>
            </div>
          </div>
          {Boolean(!loading && clubhouses.length) && (
          <div>
            <div className={styles['modal-content-titles']}>
              {chooseClubhouse}
            </div>
            <div>
              <Dropdown
                placeholder={dropdownPlaceholder}
                onOptionClicked={(clubhouseId: string) => {
                  const selectedClubhouse = clubhouses.find((
                    clubhouse,
                  ) => clubhouse.id === clubhouseId);
                  if (selectedClubhouse) {
                    setSelectedRecording({
                      uploadUuid: veoId,
                      clubSlug: selectedClubhouse.id,
                      userUuid: userId,
                    });
                  }
                }}
                options={clubhousesOptions}
              />
            </div>
          </div>
          )}

          <div className={styles['modal-submit']}>
            <Button
              buttonType={ButtonType.CANCELLED}
              label={closeButtonLabel}
              onClick={negativeActionClicked}
            />
            <Button
              submit
              buttonType={buttonType}
              label={positiveButtonLabel}
              disabled={isSubmitting}
            />
          </div>
        </form>
      </div>
    </ModalWrapper>
  );
};

export default SaveRecordingModal;
