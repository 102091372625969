import {
  combineReducers,
} from '@reduxjs/toolkit';
import userReducer from './features/userSlice';
import leagueReducer from './features/leagueSlice';
import recordingsReducer from './features/recordingsSlice';
import membersReducer from './features/membersSlice';
import teamsReducer from './features/teamsSlice';

const reducer = combineReducers({
  userReducer,
  leagueReducer,
  recordingsReducer,
  membersReducer,
  teamsReducer,
});

export default reducer;
