import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { APIStatus } from '../../enums/APIStatus';
import { IRootState } from '../store';
import { getLeagues, getAllLeagues } from '../../apis/privateApi';
import { League } from '../../types/league.type';

type State = {
  status: APIStatus,
  leagues: League[],
  currentLeague: League | null,
};

const initialState: State = {
  status: APIStatus.IDLE,
  leagues: [],
  currentLeague: null,
};

export const fetchLeagues = createAsyncThunk('league', async (userId?: string) => {
  const getLeagueRequest = userId ? getLeagues.bind(null, userId) : getAllLeagues;
  return getLeagueRequest()
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
});

const leagueSlice = createSlice({
  name: 'league',
  initialState,
  reducers: {
    setCurrentLeague: (state: State, action: PayloadAction<League>) => {
      state.currentLeague = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLeagues.pending, (state) => {
        state.status = APIStatus.PENDING;
      })
      .addCase(fetchLeagues.fulfilled, (state, action) => {
        if (action.payload) {
          state.status = APIStatus.FULFILLED;
          state.leagues = action.payload?.data;
          if (!state.currentLeague) {
            state.currentLeague = action.payload?.data[0];
          }
        } else {
          state.status = APIStatus.REJECTED;
        }
      })
      .addCase(fetchLeagues.rejected, (state) => {
        state.status = APIStatus.REJECTED;
      });
  },
});

export const { setCurrentLeague } = leagueSlice.actions;

export const getStatusSelector = (state: IRootState) => state.leagueReducer.status;
export const getLeaguesSelector = (state: IRootState) => state.leagueReducer.leagues;
export const getCurrentLeagueSelector = (state: IRootState) => state.leagueReducer.currentLeague;
export default leagueSlice.reducer;
