import { AxiosRequestConfig } from 'axios';
import axios from '../../privateApi';
import { FILE_CHUNK_SIZE } from '../../../settings';

export const startUpload = (leagueId: string) => axios.post('/start-upload', {
  league_uuid: leagueId,
}).then((res) => res.data);

export const generatePresignedUrlsParts = (uploadId: string, parts: number, key: string) => axios.post('get-upload-urls', {
  uploadId,
  parts,
  key,
}).then((res) => res.data);

export const uploadParts = async (
  file: File,
  urls: string[],
  setProgressValue: (p: number, i: number, numberOfChunks: number) => void,
) => {
  const promises = urls.map((url, i) => {
    const start = i * FILE_CHUNK_SIZE;
    const end = (i + 1) * FILE_CHUNK_SIZE;
    const config: AxiosRequestConfig = {
      onUploadProgress(progressEvent) {
        setProgressValue(
          progressEvent.progress ?? 0,
          i,
          urls.length,
        );
      },
    };
    const blob = i < urls.length ? file.slice(start, end) : file.slice(start);

    return axios.put(url, blob, config);
  });

  const resParts = await Promise.all(promises);

  return resParts.map((part, index) => ({
    ETag: (part as any).headers.etag,
    PartNumber: index + 1,
  }));
};

export const completeUpload = (uploadId: string, key: string, parts: any[]) => axios.post('complete-upload', {
  uploadId,
  parts,
  key,
}).then((res) => res.data);
