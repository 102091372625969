import axios from 'axios';
import axiosRetry from 'axios-retry';

import { LEX_API_BASE_URL, HEADERS, API_KEY_LABEL } from '../settings';

export * from './requests/leagues/matches/matches';
export * from './requests/leagues/members/members';
export * from './requests/leagues/league';
export * from './requests/leagues/teams/teams';
export * from './requests/leagues/teams/members/team_members';
export * from './requests/users/users';
export * from './requests/upload/upload';
export * from './requests/leagues/members/members';
export * from './requests/leagues/matches/matches';
export * from './requests/clubhouses/clubhouses';
export * from './requests/import/import';

const instance = axios.create({
  baseURL: LEX_API_BASE_URL,
});

instance.interceptors.request.use(
  (config) => {
    if (config.headers && sessionStorage.getItem(HEADERS.VEO_USER_ID)) {
      config.headers[HEADERS.VEO_USER_ID] = sessionStorage.getItem(HEADERS.VEO_USER_ID) || '';
    }

    if (config.headers && sessionStorage.getItem(API_KEY_LABEL)) {
      config.headers['x-api-key'] = sessionStorage.getItem(API_KEY_LABEL) || '';
    }

    return config;
  },
  (error) => Promise.reject(error),
);

axiosRetry(instance, { retries: 3 });

export default instance;
