import React, {
  useEffect, useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useErrorHandler } from 'react-error-boundary';

import { useRollbar } from '@rollbar/react';
import { fetchTeams, getTeamsSelector } from '../../redux/features/teamsSlice';
import { getUserSelector } from '../../redux/features/userSlice';
import * as privateApi from '../../apis/privateApi';
import styles from './Upload.module.scss';
import Button from '../../components/Button/Button';
import { ButtonType } from '../../enums/ButtonType';
import Dropdown from '../../components/Dropdown/Dropdown';
import ProgressBar from './progress_bar/ProgressBar';
import DeleteModal from '../../components/Modals/DeleteModal/DeleteModal';
import { FILE_CHUNK_SIZE, MAX_UPLOAD_SIZE } from '../../settings';
import { getCurrentLeagueSelector } from '../../redux/features/leagueSlice';
import { AppDispatch } from '../../redux/store';
import { fetchLeagueRecordings, postLeagueRecording } from '../../redux/features/recordingsSlice';
import Icon from '../../components/Icon/Icon';
import { EventName, telemetry } from '../../telemetry/telemetry';

interface State {
  file: File,
  leaguePublicId: string;
}

interface FinishedUpload {
  uploadId: string,
  key: string,
  parts: {
    ETag: string,
    PartNumber: number,
  }[];
}

const Upload = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const handleError = useErrorHandler();
  const dispatch = useDispatch<AppDispatch>();

  const rollbar = useRollbar();

  const { user } = useSelector(getUserSelector);
  const currentLeague = useSelector(getCurrentLeagueSelector);
  const teams = useSelector(getTeamsSelector);
  const league = useSelector(getCurrentLeagueSelector);

  const teamOptions = teams?.map((team) => ({
    label: team.team_name,
    id: team.public_identifier,
  }));

  const currentDate = new Date().toISOString().slice(0, 10);

  const [awayTeamOptions, setAwayTeamOptions] = useState(teamOptions);
  const [uploading, setUploading] = useState(false);
  const [homeTeamId, setHomeTeamId] = useState('');
  const [awayTeamId, setAwayTeamId] = useState('');
  const [homeTeamScore, setHomeTeamScore] = useState(-1);
  const [awayTeamScore, setAwayTeamScore] = useState(-1);
  const [date, setDate] = useState(currentDate);
  const [duration, setDuration] = useState(-1);
  const [matchUrl, setMatchUrl] = useState('');
  const [finishedUpload, setFinishedUpload] = useState({} as FinishedUpload);
  const state = location.state as State;
  if (!state) {
    handleError(new Error('You already uploaded this video'));
  }
  const [openModal, setOpenModal] = useState(false);

  const cancelModal = () => {
    setOpenModal(false);
  };

  useEffect(() => {
    if (currentLeague?.public_identifier) {
      dispatch(fetchTeams(currentLeague.public_identifier));
    }
  }, [currentLeague]);

  // helper functions

  // Not ideal but it is pretty safe and as long as AWS doesn't change the format
  // it should be fine.

  // TODO: Find a better way to do this.
  const splitUrl = (url: string) => {
    const splittedUrl = url.split('/');
    const urlPrefix = `${splittedUrl[0]}//${splittedUrl[1]}${splittedUrl[2]}`;
    const generatedMatchId = splittedUrl[5];
    const fileName = (splittedUrl[6].split('?'))[0];
    return {
      urlPrefix,
      generatedMatchId,
      fileName,
    };
  };
  const formIsFilledOut = homeTeamId !== ''
    && awayTeamId !== ''
    && homeTeamScore > -1
    && awayTeamScore > -1
    && duration > -1
    && date !== ''
    && matchUrl !== '';

  const canSubmit = formIsFilledOut && !uploading;

  // -----
  // Updating the state of the progress bar without rerendering this component
  let setProgress: (p: number) => any;
  const onProgressBarMount = (setProgressChild: (p: number)
  => any) => {
    setProgress = setProgressChild;
  };

  const progresses: number[] = [];
  const calculateProgress = (p: number, i: number, numberOfChunks: number) => {
    progresses[i] = p;
    const progress = progresses.reduce((partialSum, a) => partialSum + a, 0) * 100;
    // We set the progress to 100% in the callback of upload completed
    const percentage = Math.floor(progress / numberOfChunks) < 100
      ? Math.floor(progress / numberOfChunks) : 99;
    setProgress(percentage);
  };

  useEffect(() => {
    const beforeUnload = (event: any) => {
      event.preventDefault();
      event.returnValue = '';
    };
    const backButtonClicked = () => {
      // eslint-disable-next-line no-alert, no-restricted-globals
      if (confirm('Are you sure you want to go back? You will loose your progress.')) {
        navigate('/');
      } else {
        window.history.pushState(null, document.title, window.location.href);
      }
    };
    window.addEventListener('beforeunload', beforeUnload);
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener('popstate', backButtonClicked);

    return function removingListeners() {
      window.removeEventListener('popstate', backButtonClicked);
      window.removeEventListener('beforeunload', beforeUnload);
    };
  }, []);

  useEffect(() => {
    /*
    This should only occur if the user has uploaded a video and then clicks on the browser
    back button. Ideally, it would be handled different but for now this is sufficient.
    */
    if (!state) {
      handleError(new Error('You already uploaded this video'));
    }
    if (state.file.size > MAX_UPLOAD_SIZE) {
      handleError(new Error('Maximum mp4 file size is 12 GB. Try to compress or trim your video, or reach out to customer support.'));
    }

    setUploading(true);

    const video = document.createElement('video');
    video.addEventListener('loadedmetadata', () => {
      setDuration(video.duration);
    });
    video.src = URL.createObjectURL(state.file!);

    const startUpload = () => privateApi.startUpload(state.leaguePublicId).then(async (data) => {
      const { uploadId, key } = data;
      const parts = Math.ceil(state.file.size / FILE_CHUNK_SIZE);
      const signedUrls = await privateApi.generatePresignedUrlsParts(uploadId, parts, key);
      const { urlPrefix, generatedMatchId, fileName } = splitUrl(signedUrls.data[0]);
      setMatchUrl(`${urlPrefix}/${state.leaguePublicId}/videos/${generatedMatchId}/${fileName}`);
      const uploadPartsRes = await privateApi.uploadParts(
        state.file,
        signedUrls.data,
        (p, i, numberOfChunks) => {
          calculateProgress(p, i, numberOfChunks);
        },
      );
      setProgress(100);
      setFinishedUpload({ uploadId, key, parts: uploadPartsRes });

      telemetry.track(EventName.LEX_RECORDING_UPLOAD_COMPLETED);
    });
    startUpload()
      .catch((error) => {
        rollbar.error('Failed uploading video', error);
        handleError(new Error('Uh oh, uploading went wrong...'));
      })
      .finally(() => setUploading(false));
  }, []);

  useEffect(() => {
    if (uploading && duration > 0) {
      telemetry.track(EventName.LEX_RECORDING_UPLOAD_STARTED, {
        duration,
      });
    }
  }, [uploading, duration]);

  const onSubmit = async (e: { preventDefault: () => void; }) => {
    e.preventDefault();
    const match = {
      team_home_id: homeTeamId,
      team_away_id: awayTeamId,
      team_home_score: homeTeamScore,
      team_away_score: awayTeamScore,
      duration: Math.round(duration),
      recorded_date: date,
      owner_id: user?.veo_id!,
      url: matchUrl,
    };

    if (canSubmit) {
      await dispatch(postLeagueRecording({
        match,
        leagueId: state.leaguePublicId,
      }));
      await privateApi.completeUpload(
        finishedUpload.uploadId,
        finishedUpload.key,
        finishedUpload.parts,
      );

      await telemetry.track(EventName.LEX_RECORDING_PUBLISHED, {
        source: 'external',
        duration,
        matchDate: date,
      });

      dispatch(fetchLeagueRecordings({ leagueId: currentLeague!.public_identifier }));
      navigate('/');
    }
  };

  const leagueTitle = (
    <div className={styles.title}>
      {(league?.thumbnail)
        ? <img src={league?.thumbnail} alt="Crest" />
        : <Icon name="placeholderCrest" size="large" />}
      <h2 className={styles['league-name']}>{league?.name}</h2>
    </div>
  );

  // dont allow same team to be selected for both home and away
  const onHomeTeamOptionSelect = (teamId: string) => {
    if (teamId === awayTeamId) {
      setAwayTeamId('');
    }
    setAwayTeamOptions(teamOptions.filter((o) => o.id !== teamId));
    setHomeTeamId(teamId);
  };

  const uploadingTable = (
    <form action="#" method="post" onSubmit={onSubmit} className={styles['uploading-table']}>
      <h1>{uploading ? 'Upload processing...' : 'Upload finished'}</h1>
      <div className={styles.row}>
        <section className={styles.col}>
          <span className={styles.label}>
            Home Team
          </span>
          <div className={styles['upload-dropdown']}>
            <Dropdown
              clearSelectedOption={homeTeamId === ''}
              placeholder="Select Home Team"
              onOptionClicked={(teamId: string) => onHomeTeamOptionSelect(teamId)}
              options={teamOptions}
            />
          </div>
        </section>
        <section className={styles.col}>
          <span className={styles.label}>Away Team</span>
          <div className={styles['upload-dropdown']}>
            <Dropdown
              clearSelectedOption={awayTeamId === ''}
              placeholder="Select Away Team"
              onOptionClicked={(teamId: string) => setAwayTeamId(teamId)}
              options={awayTeamOptions}
            />
          </div>
        </section>
        <section className={styles.col}>
          <span className={styles.label}>
            <span className={styles.center}>
              Score
            </span>
          </span>
          <div className={styles['upload-content']}>
            <div className={styles.score}>
              <span className={styles.home}>HOME </span>
              <div className={styles['score-content']}>
                <span className={styles['score-input']}>
                  <input
                    className={styles.input}
                    type="number"
                    placeholder="0"
                    min="0"
                    required
                    onChange={(e) => setHomeTeamScore(+e.target.value)}
                  />
                </span>
                <span className={styles.separator}>-</span>
                <span className={styles['score-input']}>
                  <input
                    className={`${styles.input} ${styles.right}`}
                    type="number"
                    placeholder="0"
                    min="0"
                    required
                    onChange={(e) => {
                      setAwayTeamScore(+e.target.value);
                    }}
                  />
                </span>
              </div>
              <span className={styles.away}> AWAY</span>
            </div>
          </div>
        </section>
        <section className={styles.col}>
          <span className={styles.label}>
            <span className={styles.center}>
              Match Date
            </span>
          </span>
          <div className={styles['upload-content']}>
            <div className={styles.center}>
              <input
                type="date"
                required
                onChange={(e) => setDate(e.target.value)}
                className={styles['date-input']}
                placeholder="Select date"
                max={currentDate}
                value={date}
              />
            </div>
          </div>
        </section>
      </div>
      <footer className={styles.footer}>
        <Button label="Cancel" buttonType={ButtonType.CANCELLED} onClick={() => setOpenModal(!openModal)} />
        <Button label="Publish" buttonType={canSubmit ? ButtonType.CONFIRM : ButtonType.DISABLED} submit />
        {openModal && (
          <DeleteModal
            title="Cancel upload?"
            negativeActionLabel="Go back"
            positiveActionLabel="Cancel"
            negativeActionClicked={cancelModal}
            positiveActionClicked={async () => {
              await telemetry.track(EventName.LEX_RECORDING_PUBLISH_CANCELED);
              return navigate('/');
            }}
          >
            <p className={styles['modal-message']}>
              Are you sure?
            </p>
            <p className={styles['modal-data']}>
              If you cancel the upload now, you will lose all progression and
              will have to start from the beginning.
            </p>
          </DeleteModal>
        )}
      </footer>
    </form>
  );

  return (
    <div className={styles['upload-layout']}>
      {leagueTitle}
      <ProgressBar onMount={onProgressBarMount} fileName={state?.file?.name ?? ''} />
      {uploadingTable}
    </div>
  );
};

export default Upload;
