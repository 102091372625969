import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Button from '../Button/Button';
import { ButtonType } from '../../enums/ButtonType';
import { avatarCrest } from '../../icons';
import styles from './BackOfficeDashboard.module.scss';
import { AppDispatch } from '../../redux/store';
import {
  fetchLeagues,
  getLeaguesSelector,
} from '../../redux/features/leagueSlice';
import { getUserSelector } from '../../redux/features/userSlice';
import { isElevatedUser } from '../../auth/permissions';

const BackOfficeDashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const leagues = useSelector(getLeaguesSelector);
  const user = useSelector(getUserSelector);

  useEffect(() => {
    dispatch(fetchLeagues());
  }, []);

  const navigateToRecordings = () => {
    navigate('/');
  };

  const navigateToBackOfficeCreate = () => {
    navigate('/cockpit/create');
  };

  const navigateToLeagueEdit = (leagueId: string) => {
    navigate(`/cockpit/${leagueId}/edit`);
  };

  const orderedLeagues = [...leagues].sort((a, b) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  });

  if (!isElevatedUser(user.user)) {
    navigate('/');
  }

  return (
    <div>
      <section className={styles.header}>
        <div className={styles.heading}>
          <h2>League Exchange dashboard</h2>
          <p>Veo’s League Exchange Dashboard is where you can add leagues</p>
        </div>
        <div className={styles.actions}>
          <Button
            label="Back to recordings"
            buttonType={ButtonType.BACKOFFICE}
            onClick={navigateToRecordings}
          />
          <Button
            label="Create new League"
            buttonType={ButtonType.CONFIRM}
            onClick={navigateToBackOfficeCreate}
          />
        </div>
      </section>
      <main>
        <div className={styles['league-container']}>
          <section>
            <div className={styles['league-title']}>
              <h2>Leagues</h2>
            </div>
          </section>
          <section>
            <div className={styles['league-main-header']}>
              <div className={styles['league-name-label']}>
                League name
              </div>
            </div>
          </section>
          {orderedLeagues.map((league) => (
            <section className={styles['legaue-info']} key={league.public_identifier}>
              <div className={styles['legaue-crest']}>{avatarCrest}</div>
              <div className={styles['legaue-name-data']}>{league.name}</div>
              <Button label="Edit" buttonType={ButtonType.BACKOFFICE} onClick={() => navigateToLeagueEdit(league.public_identifier)} />
            </section>
          ))}
        </div>
      </main>
    </div>
  );
};

export default BackOfficeDashboard;
