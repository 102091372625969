import { TeamUpdate } from '../../../../types/team.type';
import axios from '../../../privateApi';

export const getTeams = (leagueId: string) => axios.get(`leagues/${leagueId}/teams`);

export const getUserTeams = (leagueId: string, userId: string) => axios.get(`leagues/${leagueId}/teams?user_id=${userId}`);

export const updateTeam = (leagueId: string, teamId: string, data: TeamUpdate) => (
  axios.put(`leagues/${leagueId}/teams/${teamId}`, data)
);

export const createTeam = (leagueId: string, data: TeamUpdate) => (
  axios.post(`leagues/${leagueId}/teams`, data)
);

export const deleteTeam = (leagueId: string, teamId: string) => (
  axios.delete(`leagues/${leagueId}/teams/${teamId}`)
);
