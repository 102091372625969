import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AppDispatch } from '../../../redux/store';
import Options from '../../Options/Options';
import EditRecordingModal from '../../Modals/EditRecording/EditRecordingModal';
import DeleteModal from '../../Modals/DeleteModal/DeleteModal';
import { canManageRecording } from '../../../auth/permissions';
import {
  deleteLeagueRecording,
  editLeagueRecording,
  fetchLeagueRecordings,
  importRecordingToClub,
} from '../../../redux/features/recordingsSlice';
import {
  getCurrentLeagueSelector,
} from '../../../redux/features/leagueSlice';
import { EditMatch } from '../../../apis/privateApi';
import styles from './RecordingsListItemOptions.module.scss';
import SaveRecordingModal from '../../Modals/SaveRecording/SaveRecordingModal';
import { MatchImportData } from '../../../types/match_import.type';
import { EventName, telemetry } from '../../../telemetry/telemetry';

interface RecItemOptionsPropTypes {
  recordingId: any;
  recordingTitle: string;
  recordingThumbnail?: string;
  recordingDuration?: string;
  icon?: JSX.Element;
  veoId?: string;
}

function RecordingsListItemOptions({
  icon,
  recordingId,
  recordingTitle,
  recordingThumbnail,
  recordingDuration,
  veoId,
}: RecItemOptionsPropTypes) {
  const dispatch = useDispatch<AppDispatch>();
  const currentLeague = useSelector(getCurrentLeagueSelector);

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openImportModal, setOpenImportModal] = useState(false);

  const recordingEdited = async (
    leagueId: string,
    matchId: string,
    updatedValues: EditMatch,
  ) => {
    if (currentLeague?.public_identifier) {
      await dispatch(editLeagueRecording({ leagueId, matchId, updatedValues }));
      dispatch(fetchLeagueRecordings({ leagueId: currentLeague!.public_identifier }));
      setOpenEditModal(false);
    }
  };

  const cancelDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const cancelEditModal = () => {
    setOpenEditModal(false);
  };

  const cancelImportModal = () => {
    setOpenImportModal(false);
  };

  const onClickDelete = () => {
    setOpenDeleteModal(true);
  };

  const onClickEdit = () => {
    setOpenEditModal(true);
  };

  const onClickImport = () => {
    setOpenImportModal(true);
  };

  const deleteRecording = async () => {
    if (currentLeague?.public_identifier) {
      await dispatch(deleteLeagueRecording({
        leagueId: currentLeague.public_identifier,
        recordingId,
        recordingTitle,
      }));

      await telemetry.track(EventName.LEX_RECORDING_DELETED);

      dispatch(fetchLeagueRecordings({ leagueId: currentLeague.public_identifier }));
    }
  };

  const importRecording = async (data: MatchImportData) => {
    if (currentLeague?.public_identifier) {
      const leagueId = currentLeague.public_identifier;
      const res = await dispatch(importRecordingToClub({
        data,
        leagueId,
      }));

      if (res.meta.requestStatus === 'fulfilled') {
        await telemetry.track(EventName.LEX_RECORDING_IMPORTED_TO_CLUB);
      }

      setOpenImportModal(false);
    }
  };

  const options = [
    { label: 'Edit', onClick: onClickEdit, id: 0 },
    { label: 'Delete', onClick: onClickDelete, id: 1 },
  ];

  if (veoId) {
    options.push({ label: 'Import', onClick: onClickImport, id: 2 });
  }

  return (
    <>
      {canManageRecording(currentLeague?.permission) && (
        <Options
          options={options}
          icon={icon}
        />
      )}
      {openEditModal && (
        <EditRecordingModal
          recordingId={recordingId}
          recordingName={recordingTitle}
          negativeActionClicked={cancelEditModal}
          positiveActionClicked={recordingEdited}
        />
      )}
      {openDeleteModal && (
        <DeleteModal
          title="Delete recording"
          negativeActionClicked={cancelDeleteModal}
          positiveActionClicked={deleteRecording}
        >
          <p className={styles['modal-message']}>
            Are you sure you want to delete the recording?
          </p>
          <p className={styles['modal-data']}>
            {recordingTitle}
          </p>
        </DeleteModal>
      )}
      {veoId && openImportModal && (
        <SaveRecordingModal
          veoId={veoId}
          recordingTitle={recordingTitle}
          recordingThumbnail={recordingThumbnail}
          recordingDuration={recordingDuration}
          negativeActionClicked={cancelImportModal}
          positiveActionClicked={importRecording}
        />
      )}
    </>
  );
}

export default RecordingsListItemOptions;
