import React from 'react';
import styles from './ErrorPage.module.scss';
import { veoLogo } from '../../icons';
import Button from '../Button/Button';
import { ButtonType } from '../../enums/ButtonType';

type Props = {
  title: string;
  text: string;
  warningText?: JSX.Element
};

const ErrorPage = ({
  title, text, warningText,
}: Props) => {
  const navigateHome = () => {
    window.parent.location.href = '/';
  };
  return (
    <div className={styles['error-page']}>
      <div className={styles['veo-logo-dark']}>{veoLogo}</div>
      <div className={styles['error-page-title']}>
        {title}
      </div>
      <div className={styles['error-page-text']}>
        {text}
      </div>
      <Button
        buttonType={ButtonType.DARK}
        label="Go to dashboard"
        onClick={navigateHome}
      />
      {warningText && <div className={styles['error-page-line']} />}
      <div>
        {warningText}
      </div>
    </div>
  );
};

export default ErrorPage;
