import { capitalize } from '../utils/utils';

export enum Role {
  ADMIN = 'ADMIN',
  EDITOR = 'EDITOR',
  VIEWER = 'VIEWER',
  LEAGUE_OWNER = 'LEAGUE_OWNER',
}

export const getUserRoles = () => Object.values(Role).filter((role) => role !== Role.LEAGUE_OWNER);

export const formatRole = (value: string) => (value === Role.VIEWER ? 'Contributor' : capitalize(value));
