/* eslint-disable no-alert */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as privateApi from '../../apis/privateApi';
import Button from '../Button/Button';
import { ButtonType } from '../../enums/ButtonType';
import { League, LeagueOwner, MemberType } from '../../types/league.type';
import Card from '../Card/Card';
import LeagueForm from './Forms/LeagueForm';
import TeamForm from './Forms/TeamForm';
import { addLeagueCrest } from '../../icons';
import styles from './CreateOrEditLeague.module.scss';
import formStyles from './Forms/Form.module.scss';
import MemberForm from './Forms/MemberForm';
import { TeamCreate } from '../../types/team.type';
import { TeamMemberCreate } from '../../types/team_member.type';
import { Role } from '../../enums/Role';

const BackOfficeCreateLeague = () => {
  const [isSaving, setIsSaving] = useState(false);
  const [crest, setCrest] = useState<File | undefined>();
  const [isValidLeague, setIsValidLeague] = useState(false);
  const [leagueInfo, setLeagueInfo] = useState<League>({
    name: '',
    thumbnail: '',
    permission: Role.LEAGUE_OWNER,
    public_identifier: '',
    owners: [{
      id: '', email: '', registered: false,
    }],
  });
  const [teamsInfo, setTeamsInfo] = useState([
    {
      code: '', name: '', id: 0,
    },
    {
      code: '', name: '', id: 1,
    },
  ]);
  const [membersInfo, setMembersInfo] = useState([
    {
      email: '', permission: Role.ADMIN, id: 0, team_id: 0,
    },
    {
      email: '', permission: Role.ADMIN, id: 1, team_id: 1,
    },
  ]);

  useEffect(() => {
    const form = document.getElementById('league-create-form');
    if (form instanceof HTMLFormElement) {
      setIsValidLeague(form.checkValidity());
    }
  }, [teamsInfo, membersInfo, leagueInfo]);

  const handleTeamAddClick = () => {
    const newTeamId = teamsInfo[teamsInfo.length - 1].id + 1;
    setTeamsInfo([...teamsInfo, {
      code: '', name: '', id: newTeamId,
    }]);
    setMembersInfo([...membersInfo, {
      email: '', permission: Role.ADMIN, id: 0, team_id: newTeamId,
    }]);
  };

  const handleMemberAddClick = (teamIndex: number) => {
    const id = membersInfo[membersInfo.length - 1].id + 1;
    setMembersInfo([...membersInfo, {
      email: '', permission: Role.ADMIN, id, team_id: teamIndex,
    }]);
  };

  const onCrestChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.[0]) {
      setCrest(e.target.files[0]);
    } else {
      setCrest(undefined);
    }
  };

  const handleLeagueChange = (name: string) => setLeagueInfo({ ...leagueInfo, name });

  const handleTeamChange = (team: TeamCreate, teamId: number) => {
    const teamList = [...teamsInfo];
    teamsInfo
      .map((_team) => {
        if (_team.id === teamId) {
          _team.name = team.team_name;
          _team.code = team.abbreviation;
        }
        return _team;
      });

    setTeamsInfo(teamList);
  };

  const handleMemberChange = (
    member: TeamMemberCreate,
    teamId: number,
    memberId: number,
  ) => {
    const membersList = [...membersInfo];

    membersList.map(
      (_member) => {
        if (_member.team_id === teamId && _member.id === memberId) {
          _member.email = member.email;
          _member.permission = member.permission;
        }
        return _member;
      },
    );
    setMembersInfo(membersList);
  };

  /* delete rows */

  const deleteTeam = (teamId: number) => {
    if (teamsInfo.length <= 2) {
      alert('You must have at least two teams.');
      return;
    }
    const teamList = [...teamsInfo];
    const memberList = [...membersInfo];
    const updatedTeamList = teamList.filter((t) => t.id !== teamId);
    const updatedmemberList = memberList.filter((c) => c.team_id !== teamId);

    setTeamsInfo(updatedTeamList);
    setMembersInfo(updatedmemberList);
  };

  const deleteMember = (teamId: number, memberId: number) => {
    const membersList = [...membersInfo];
    const memberToDeleteIndex = membersList
      .findIndex((c) => c.team_id === teamId && c.id === memberId);

    membersList.splice(memberToDeleteIndex, 1);
    setMembersInfo(membersList);
  };

  const handleLeagueOwnerAdd = () => {
    setLeagueInfo((prevState) => ({
      ...prevState,
      owners: [...prevState.owners, {
        id: '', email: '',
      }],
    }));
  };

  const handleLeagueOwnerRemove = (owner: LeagueOwner) => {
    setLeagueInfo((prevState) => ({
      ...prevState,
      owners: prevState.owners.filter((_owner) => _owner.id !== owner.id),
    }));
  };

  const handleLeagueOwnerChange = (leagueOwner: LeagueOwner) => {
    setLeagueInfo((prevState) => ({
      ...prevState,
      owners: prevState.owners.map((owner) => {
        if (owner.id === leagueOwner.id) {
          return leagueOwner;
        }
        return owner;
      }),
    }));
  };

  /* reset rows */

  const resetInputField = () => {
    setCrest(undefined);
    setLeagueInfo({
      name: '', thumbnail: '', permission: Role.LEAGUE_OWNER, public_identifier: '', owners: [],
    });
    setTeamsInfo([
      {
        code: '', name: '', id: 0,
      },
      {
        code: '', name: '', id: 1,
      },
    ]);
    setMembersInfo([
      {
        email: '', permission: Role.ADMIN, id: 0, team_id: 0,
      },
      {
        email: '', permission: Role.ADMIN, id: 1, team_id: 1,
      },
    ]);
  };

  const navigate = useNavigate();
  const navigateToBackOfficeDashboard = () => {
    navigate('/cockpit');
  };

  const preparePostData = () => {
    const teamsAndMembers = teamsInfo.map((team) => {
      const teamMembers: MemberType[] = [];
      membersInfo.forEach((member) => {
        if (member.team_id === team.id) {
          teamMembers.push({
            email: member.email,
            permission: member.permission,
          });
        }
      });

      return {
        team: {
          team_name: team.name,
          thumbnail: '',
          abbreviation: team.code,
        },
        members: teamMembers,
      };
    });

    return {
      name: leagueInfo.name,
      thumbnail: null,
      teams_and_members: teamsAndMembers,
      owners: leagueInfo.owners,
    };
  };

  const saveCrest = async (league: any) => {
    if (!crest) return;
    const fileExtension = crest.name.slice(crest.name.lastIndexOf('.') + 1);
    const uploadUrl = await privateApi.getLeagueCrestUploadUrl(
      league.public_identifier,
      fileExtension,
    )
      .then((response) => response.data.league_crest_upload_url);
    await privateApi.uploadLeagueCrest(crest, uploadUrl);
    await privateApi.updateLeague(league.public_identifier, {
      name: league.name,
      thumbnail: `${new URL(uploadUrl).origin}/${league.public_identifier}/images/crest_original.${fileExtension}`,
    });
  };

  const createLeague = async () => {
    if (!isValidLeague) return;
    setIsSaving(true);
    const postData = preparePostData();
    const league = await privateApi.createLeague(postData)
      .then((response) => response.data)
      .catch(() => {
        setIsSaving(false);
        alert('There was an error while saving the league. Please try again.');
      });
    await saveCrest(league)
      .catch(() => {
        alert('The league has been created, but the crest could not be saved.');
      });
    navigate('/cockpit');
  };

  return (
    <div>
      <form id="league-create-form" className={styles.form}>
        <section className={styles.header}>

          {/* League crest */}
          <div className={styles['league-crest']}>
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label>
              {(crest) ? <img src={URL.createObjectURL(crest)} alt="League Crest" /> : addLeagueCrest}
              <input type="file" accept="image/*" onChange={onCrestChange} />
            </label>
          </div>

          {/* Heading */}
          <div className={styles.heading}>
            <h2>Create League</h2>
          </div>

          {/* Actions */}
          <div className={styles.actions}>
            <Button
              label="Go back"
              buttonType={ButtonType.BACKOFFICE}
              onClick={navigateToBackOfficeDashboard}
            />
            <Button
              label="Reset"
              buttonType={ButtonType.CONFIRM}
              onClick={resetInputField}
              disabled={false}
            />
            <Button
              label="Publish League"
              buttonType={ButtonType.CONFIRM}
              onClick={createLeague}
              disabled={!isValidLeague || isSaving}
            />
          </div>
        </section>
        {/* League form section */}
        <Card>
          <section className={styles.section}>
            <h2 className={styles['section-heading']}>League information</h2>
            <LeagueForm
              league={leagueInfo}
              leagueOwners={leagueInfo.owners}
              onChange={(league) => handleLeagueChange(league as string)}
              onLeagueOwnerChange={handleLeagueOwnerChange}
              onLeagueOwnerAdd={handleLeagueOwnerAdd}
              onLeagueOwnerRemove={handleLeagueOwnerRemove}
              disabled={false}
            />
          </section>
        </Card>

        {/* Team forms section */}
        {teamsInfo.map((team, teamIndex) => (
          <Card>
            <section className={styles.section}>
              <div className={styles['section-header']}>
                <h2 className={styles['section-heading']}>
                  Add Team&nbsp;
                  {teamIndex + 1}
                </h2>
                <Button
                  label="Delete Team"
                  buttonType={ButtonType.BACKOFFICE}
                  onClick={() => deleteTeam(team.id)}
                />
              </div>
              <TeamForm
                team={{
                  team_name: team.name,
                  abbreviation: team.code,
                } as TeamCreate}
                onChange={(_team) => handleTeamChange(_team, teamIndex)}
              />
            </section>

            <section className={styles.section}>
              <h2 className={styles['section-heading']}>Add Members</h2>

              <div className={formStyles.row}>
                <div className={formStyles.label}>Email</div>
                <div className={formStyles.label}>Permission</div>
                <div />
                <div />
                <div />
                <div />
                {membersInfo
                  .filter((member) => member.team_id === team.id)
                  .map((member) => (
                    <MemberForm
                      newUser
                      fromBackOfficeEdit={false}
                      member={{
                        email: member.email,
                        permission: member.permission,
                      }}
                      onChange={(value) => handleMemberChange(value, member.team_id, member.id)}
                      onDelete={() => deleteMember(member.team_id, member.id)}
                    />
                  ))}
              </div>
              <section className={styles['add-member']}>
                <Button
                  label="Add Member"
                  buttonType={ButtonType.BACKOFFICE}
                  onClick={() => handleMemberAddClick(team.id)}
                />
              </section>
            </section>
          </Card>
        ))}

        <section className={styles['add-team']}>
          <Button
            label="Add Team"
            buttonType={ButtonType.BACKOFFICE}
            onClick={handleTeamAddClick}
          />
        </section>
      </form>
    </div>
  );
};

export default BackOfficeCreateLeague;
