export const capitalize = (str: string) => (
  str[0].toUpperCase() + str.slice(1).toLocaleLowerCase()
);

export const validateEmail = (email: string) => {
  const re = /^\S+@\S+\.\S+$/;
  return re.test(email);
};

// Given an ICSS object and a list of class names returns a string of scoped
// class names.
// Useful when applying multiple classes to a single element.
export const joinStyles = (icssObj: Record<string, string>, classNames: string[]) => (
  classNames
    .map((className) => icssObj[className])
    .join(' ')
);
