import { configureStore, PreloadedState } from '@reduxjs/toolkit';
import reducer from './rootReducer';

const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
  preloadedState: {},
});

export type IRootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export default store;

// function to setup store for testing...
export type RootState = ReturnType<typeof reducer>
export function setupStore(preloadedState?: PreloadedState<RootState>) {
  return configureStore({
    reducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
    preloadedState,
  });
}
export type AppStore = ReturnType<typeof setupStore>
