import React from 'react';

import styles from './ActionButton.module.scss';

type Props = {
  onClick: () => any;
  icon: any;
  label: string;
};

const ActionButton = ({ onClick, icon, label }: Props) => (
  <div>
    <button
      type="button"
      onClick={onClick}
      className={styles['action-button']}
    >
      {icon}
      {label}
    </button>
  </div>
);

export default ActionButton;
