import React from 'react';
import { useSelector } from 'react-redux';
import { MessageType } from '../../enums/MessageType';
import { getRecordingsMessage } from '../../redux/features/recordingsSlice';
import SuccessModal from './MessageModals/SuccessModal';
import styles from './Message.module.scss';
import { getMembersMessage } from '../../redux/features/membersSlice';

function Message() {
  const recordingMessage = useSelector(getRecordingsMessage);
  const membersMessage = useSelector(getMembersMessage);

  let modal = null;
  if (recordingMessage.message || recordingMessage.title) {
    switch (recordingMessage.type) {
      case MessageType.DELETED:
        modal = (
          <SuccessModal
            message={recordingMessage.message}
            title={recordingMessage.title}
            type={recordingMessage.type}
          />
        );
        break;
      case MessageType.UPDATED:
        modal = (
          <SuccessModal
            message={recordingMessage.message}
            title={recordingMessage.title}
            type={recordingMessage.type}
          />
        );
        break;
      case MessageType.ERROR:
        modal = (
          <SuccessModal
            message={recordingMessage.message}
            title={recordingMessage.title}
            type={recordingMessage.type}
          />
        );
        break;
      default:
        break;
    }
  }

  if (membersMessage.message || membersMessage.title) {
    switch (membersMessage.type) {
      case MessageType.DELETED:
        modal = (
          <SuccessModal
            message={membersMessage.message}
            title={membersMessage.title}
            type={membersMessage.type}
          />
        );
        break;
      case MessageType.UPDATED:
        modal = (
          <SuccessModal
            message={membersMessage.message}
            title={membersMessage.title}
            type={membersMessage.type}
          />
        );
        break;
      case MessageType.ERROR:
        modal = (
          <SuccessModal
            message={membersMessage.message}
            title={membersMessage.title}
            type={membersMessage.type}
          />
        );
        break;
      default:
        break;
    }
  }

  return (
    <div className={styles['message-layout']}>
      {modal}
    </div>
  );
}

export default Message;
