import React from 'react';

import { ButtonType } from '../../../enums/ButtonType';
import Button from '../../Button/Button';
import styles from './DeleteModal.module.scss';
import ModalWrapper from '../ModalWrapper';

// negativeActionClicked and positiveActionClicked are props for cancel and confirm/delete clicks
type Props = {
  title: string,
  buttonType?: ButtonType,
  negativeActionLabel?: string,
  positiveActionLabel?: string,
  negativeActionClicked: () => any;
  positiveActionClicked: () => any;
  children: React.ReactNode;
};

function DeleteModal({
  title,
  buttonType = ButtonType.DELETE,
  negativeActionLabel = 'Cancel',
  positiveActionLabel = 'Delete',
  negativeActionClicked,
  positiveActionClicked,
  children,
}: Props) {
  return (
    <ModalWrapper isResponsiveModal>
      <div className={styles['modal-content']}>
        <h2 className={styles['modal-title']}>
          {title}
        </h2>
        <div>{children}</div>
        <div className={styles['modal-submit']}>
          <Button
            buttonType={ButtonType.CANCELLED}
            label={negativeActionLabel}
            onClick={negativeActionClicked}
          />
          <Button
            buttonType={buttonType}
            label={positiveActionLabel}
            onClick={positiveActionClicked}
          />
        </div>
      </div>
    </ModalWrapper>
  );
}

export default DeleteModal;
