import React from 'react';
import RecordingsListItem from './RecordingsListItem';
import ListHeader from '../ListHeader';

import styles from './RecordingsList.module.scss';

interface Item {
  id: string,
  duration: string;
  date: any,
  thumbnail: string,
  url: string,
  homeTeamAbbreviation: string,
  awayTeamAbbreviation: string,
  homeScore: number,
  awayScore: number,
  homeTeam: string,
  awayTeam: string,
  veoId: string,
}

type Props = {
  items: Item[];
};

function RecordingsList({
  items,
}: Props) {
  return (
    <div>
      <ListHeader
        labels={['Recording title', '', 'Score', 'Date']}
      />
      {
        items.length
          ? (
            items.map((item) => (
              <RecordingsListItem
                key={item.id}
                id={item.id}
                duration={item.duration}
                homeTeam={item.homeTeam}
                awayTeam={item.awayTeam}
                homeTeamAbbreviation={item.homeTeamAbbreviation}
                awayTeamAbbreviation={item.awayTeamAbbreviation}
                homeScore={item.homeScore}
                awayScore={item.awayScore}
                date={item.date}
                thumbnail={item.thumbnail}
                url={item.url}
                veoId={item.veoId}
              />
            ))
          )
          : (
            <div className={styles['recording-list-empty']}>
              League has no recordings yet
            </div>
          )
      }
    </div>

  );
}

export default RecordingsList;
