import React, { useState } from 'react';
import { Team, TeamCreate } from '../../../types/team.type';
import styles from './Form.module.scss';

type Props = {
  team: Team | TeamCreate;
  onChange: (team: Team | TeamCreate) => void;
  disabled?: boolean;
};

type FormValidity = {
  team_name?: ValidityState;
  abbreviation?: ValidityState;
};

const TeamForm = ({
  team, onChange, disabled,
}: Props) => {
  const [validity, setValidity] = useState<FormValidity>({});

  const checkValidity = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, validity: newValidity } = event.target;
    setValidity({ ...validity, [name]: newValidity });
  };

  const onFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    checkValidity(event);
    onChange({
      ...team,
      [name]: value,
    });
  };

  const teamNameError = (validity.team_name?.valueMissing)
    ? 'The team name is required'
    : '';

  const abbreviationError = (): string => {
    if (validity.abbreviation?.valueMissing) {
      return 'The team abbreviation is required';
    }
    if (validity.abbreviation?.patternMismatch) {
      return 'The abbreviation must be three or four characters';
    }
    return '';
  };

  return (
    <div className={styles.row}>
      <div>
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label>
          <div className={styles.label}>Team name</div>
          <div>
            <input
              type="text"
              name="team_name"
              placeholder="Team name"
              required
              onChange={onFieldChange}
              value={team.team_name}
              disabled={disabled}
            />
          </div>
        </label>
        <div className={styles['error-feedback']}>{teamNameError}</div>
      </div>
      <div>
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label>
          <div className={styles.label}>Team code</div>
          <div>
            <input
              type="text"
              name="abbreviation"
              placeholder="Team code"
              pattern=".{3,4}"
              required
              onChange={onFieldChange}
              value={team.abbreviation}
              disabled={disabled}
            />
          </div>
        </label>
        <div className={styles['error-feedback']}>{abbreviationError()}</div>
      </div>
    </div>
  );
};

export default TeamForm;
