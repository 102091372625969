import { AnalyticsBrowser } from '@segment/analytics-next';
import { SEGMENT_WRITE_KEY } from '../settings';

export enum EventName {
  LEX_RECORDING_VIEWED = 'LEX Recording Viewed',
  LEX_RECORDING_UPLOAD_STARTED = 'LEX Recording Upload Started',
  LEX_RECORDING_UPLOAD_COMPLETED = 'LEX Recording Upload Completed',
  LEX_RECORDING_DOWNLOADED = 'LEX Recording Downloaded',
  LEX_RECORDING_PUBLISHED = 'LEX Recording Published',
  LEX_RECORDING_PUBLISH_CANCELED = 'LEX Recording Publish Canceled',
  LEX_RECORDING_DELETED = 'LEX Recording Deleted',
  LEX_RECORDING_IMPORTED_TO_CLUB = 'LEX Recording Imported To Club',
  LEX_USER_INVITED = 'LEX User Invited',
  LEX_USER_DELETED = 'LEX User Deleted',
}

type RecordingViewedEvent = {
  type: EventName.LEX_RECORDING_VIEWED;
}

type RecordingUploadStartedEvent = {
  type: EventName.LEX_RECORDING_UPLOAD_STARTED
  payload: {
    duration: number
  };
}

type RecordingUploadCompletedEvent = {
  type: EventName.LEX_RECORDING_UPLOAD_COMPLETED
}

type RecordingDownloadedEvent = {
  type: EventName.LEX_RECORDING_DOWNLOADED
}

type RecordingAddedEvent = {
  type: EventName.LEX_RECORDING_PUBLISHED
  payload: {
    source: 'veo' | 'external'
    duration: number
    matchDate: string
  };
}

type RecordingPublishCanceledEvent = {
  type: EventName.LEX_RECORDING_PUBLISH_CANCELED
}

type RecordingDeletedEvent = {
  type: EventName.LEX_RECORDING_DELETED
}

type RecordingImportedToClubEvent = {
  type: EventName.LEX_RECORDING_IMPORTED_TO_CLUB
}

type UserInvitedEvent = {
  type: EventName.LEX_USER_INVITED
}

type UserDeletedEvent = {
  type: EventName.LEX_USER_DELETED
}

type TelemetryEvent =
  | RecordingViewedEvent
  | RecordingUploadStartedEvent
  | RecordingUploadCompletedEvent
  | RecordingDownloadedEvent
  | RecordingAddedEvent
  | RecordingPublishCanceledEvent
  | RecordingDeletedEvent
  | RecordingImportedToClubEvent
  | UserInvitedEvent
  | UserDeletedEvent

const analyticsBrowser = AnalyticsBrowser.load({ writeKey: SEGMENT_WRITE_KEY });

export const telemetry = {
  page: (category: string) => analyticsBrowser.page(category),
  // Inspired by https://www.totaltypescript.com/tips/use-generics-to-dynamically-specify-the-number-and-type-of-arguments-to-functions
  track: <Type extends TelemetryEvent['type']>(
    // eslint-disable-next-line max-len
    ...[eventName, payload]: Extract<TelemetryEvent, { type: Type }> extends { payload: infer TPayload extends object }
      ? [eventName: Type, payload: TPayload]
      : [eventName: Type]
  ) => (
    analyticsBrowser.track(eventName, payload)
  ),
};
