import React, { useState } from 'react';
import { TeamMember, TeamMemberCreate } from '../../../types/team_member.type';
import IconButton from '../../Button/IconButton';
import Dropdown from '../../Dropdown/Dropdown';
import Chip from '../../Chip/Chip';
import Icon from '../../Icon/Icon';
import styles from './Form.module.scss';
import { formatRole, getUserRoles, Role } from '../../../enums/Role';

type Props = {
  member: TeamMember | TeamMemberCreate;
  newUser: boolean;
  fromBackOfficeEdit: boolean;
  onChange: (member: TeamMember | TeamMemberCreate) => void;
  onDelete: (member: TeamMember | TeamMemberCreate) => void;
  disabled?: boolean;
};

type FormValidity = {
  email?: ValidityState;
};

const MemberForm = ({
  member,
  newUser,
  fromBackOfficeEdit,
  onChange,
  onDelete,
  disabled,
}: Props) => {
  const [validity, setValidity] = useState<FormValidity>({});
  const checkValidity = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, validity: newValidity } = event.target;
    setValidity({ ...validity, [name]: newValidity });
  };

  const permissions = getUserRoles()
    .map((role) => ({
      label: formatRole(role),
      id: role,
    }));

  const onFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    checkValidity(event);
    onChange({
      ...member,
      [name]: value,
    });
  };

  const onPermissionChange = (permission: Role) => {
    if (member) {
      onChange({
        ...member,
        permission,
      });
    }
  };

  const emailError = (): string => {
    if (validity.email?.valueMissing) {
      return 'The email is required';
    }
    if (validity.email?.typeMismatch) {
      return 'Please enter a valid email address';
    }
    return '';
  };

  return (
    <>
      <div>
        <div>
          <input
            type="email"
            name="email"
            placeholder="Email"
            required
            onChange={onFieldChange}
            onBlur={checkValidity}
            value={member.email}
            disabled={!newUser}
          />
        </div>
        <div className={styles['error-feedback']}>{emailError()}</div>
      </div>
      {(newUser && fromBackOfficeEdit) && (
      <div />
      )}
      {(newUser && fromBackOfficeEdit) && (
      <div />
      )}
      {!newUser && (
      <div>
        <div>
          <input
            type="text"
            name="first_name"
            placeholder="First name"
            value={(member as TeamMember).first_name || '-'}
            disabled
          />
        </div>
      </div>
      )}
      {!newUser && (
      <div>
        <div>
          <input
            type="text"
            name="last_name"
            placeholder="Last name"
            value={(member as TeamMember).last_name || '-'}
            disabled
          />
        </div>
      </div>
      )}
      <div>
        <Dropdown
          placeholder={formatRole(member.permission)}
          onOptionClicked={(permission) => onPermissionChange(permission as Role)}
          options={permissions}
        />

      </div>
      {!newUser && (
      <div className={styles.status}>
        <Chip
          tagText={((member as TeamMember).registered) ? 'ACCEPTED' : 'PENDING'}
          color={((member as TeamMember).registered) ? 'green' : undefined}
        />
      </div>
      )}
      {(newUser && fromBackOfficeEdit) && (
      <div />)}
      <div className={styles.action}>
        <IconButton onClick={() => onDelete(member)} disabled={disabled}>
          <Icon name="trashBin" color="default" size="regular" />
        </IconButton>
      </div>
      {(newUser && !fromBackOfficeEdit) && (
      <div />
      )}
      {(newUser && !fromBackOfficeEdit) && (
      <div />
      )}
      {(newUser && !fromBackOfficeEdit) && (
      <div />
      )}
    </>
  );
};

export default MemberForm;
